.alertPopUp {
  position: fixed;
  background-color: red;
  top: 120px;
  right: 30px;
  z-index: 50;
  padding: 10px 15px;
  border-radius: 8px;
  font-family: $HB !important;
  opacity: 0;
  pointer-events: none;
  animation: dropAlert 3s 0.5s forwards ease-in;
}

@keyframes dropAlert {
  from {
    -webkit-transform: translate(0, -100px);
  }
  10% {
    -webkit-transform: translate(0, 20px);
  }
  12% {
    -webkit-transform: translate(0, 22px);
  }
  16% {
    -webkit-transform: translate(0, 20px);
  }
  80% {
    -webkit-transform: translate(0, 20px);
  }
  85% {
    -webkit-transform: translate(0, 25px);
  }
  to {
    -webkit-transform: translate(0, -100px);
  }
}
