.bandeauLocalisation {
  height: 280px;
  display: flex;
  .bandeauLocalisationFlex{
    display: flex;
    p, a{
      margin-left: 8px;
    }
    svg{
      path{
        fill: $color-1;
      }
    }
  }
  .bandeauLocalisationLeft {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-3;
    div {
      // width: 50%;
      margin-bottom: 20px;
    }
  }
  .bandeauLocalisationRight {
    height: 100%;
    width: 50%;
    overflow: hidden;
  }
}

.bandeauDevis {
  height: 630px;
  display: flex;
  .bandeauDevisLeft {
    width: 50%;
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .bandeauDevisRight {
    height: 100%;
    width: 50%;
    background-color: $color-3;
    padding: 70px;
    ol {
      margin-top: 30px;
      padding-left: 20px;
      li {
        margin-bottom: 20px;
      }
    }
  }
}
