.vectorPlanShowroom {
  height: 400px;
  max-height: 440px;
  position: absolute;
  left: 40%;
  transform: translate(-50%, 0);
  img {
    // left: -15%;
    // position: absolute;
    // top: 50%;
    // transform: translate(0%, -50%);
    width: 1440px;
  }
  .MuiTypography-root.link1,
  .MuiTypography-root.link2,
  .MuiTypography-root.link3,
  .MuiTypography-root.link4 {
    cursor: pointer;
    font-size: 24px;
    text-decoration: underline;
    color: $black;
    position: relative;
    span {
      color: $black;
      display: block;
      text-transform: uppercase;
      font-size: 10px;
    }
    &::before {
      content: "";
      clear: both;
      display: block;
      position: absolute;
    }
    &::after {
      content: "";
      clear: both;
      display: block;
      position: absolute;
    }
  }
  .containShowroomLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .MuiTypography-root.link1 {
    &::after {
      background-image: url(../assets/line_vestiaire.svg);
      height: 64px;
      width: 75px;
      background-repeat: no-repeat;
      top: 50px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
  .MuiTypography-root.link2 {
    padding-left: 150px;
    span {
      padding-left: 150px;
    }
    &::after {
      background-image: url(../assets/line_mobilier.svg);
      height: 142px;
      width: 144px;
      background-repeat: no-repeat;
      top: 50px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
  .MuiTypography-root.link3 {
    padding-right: 200px;
    &::before {
      background-image: url(../assets/line_agencement.svg);
      height: 72px;
      width: 136px;
      background-repeat: no-repeat;
      bottom: 60px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
  .MuiTypography-root.link4 {
    &::before {
      background-image: url(../assets/line_accueil.svg);
      height: 64px;
      width: 75px;
      background-repeat: no-repeat;
      bottom: 60px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
  .showroomLinkTop {
    width: 100%;
    height: 100px;
  }
  .showroomLinkBottom {
    width: 100%;
    height: 100px;
  }
  .showroomLink {
    height: 100px;
  }
  .showroomLink1 {
    position: absolute;
    left: 35%;
  }
  .showroomLink2 {
    position: absolute;
    right: 320px;
    top: -50%;
  }
  .showroomLink3 {
    position: absolute;
    display: flex;
    align-items: flex-end;
    left: 42%;
  }
  .showroomLink4 {
    position: absolute;
    right: 154px;
    display: flex;
    align-items: flex-end;
  }
}
.filterShowroom {
  height: 77px;
  background-color: $white;
  display: flex;
  justify-content: center;
  .containerBtnShowroom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btnShowroomFilters {
    min-height: 77px;
    position: relative;
    cursor: pointer;
    margin: 0 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .imgShowroomIcon {
      margin-bottom: 10px;
    }
    .MuiListItemText-primary {
      font-size: 14px;
      color: $black;
      text-transform: uppercase;
    }
    .MuiListItemIcon-root {
      display: flex;
      justify-content: center;
    }
    .dFlex {
      display: flex;
      align-items: center;
      .MuiListItemText {
        margin-bottom: 0;
      }
    }
  }
  .showroomCollapse {
    position: absolute;
    left: 24px;
    top: 77px;
    .dFlex {
      width: 190px;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
.containPlanNiveau {
  position: relative;
  padding-bottom: 196px;
  .planNiveau_numero_1 {
    padding-top: 46px;
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    padding-bottom: 96px;
    &::before {
      content: "";
      background-image: url("../assets/plan_batiment_interne_bas.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      opacity: 0.5;
      display: block;
      position: relative;
      width: 100%;
      height: 440px;
    }
  }
  .showNiveau1 {
    &::before {
      content: "";
      background-image: url("../assets/plan_batiment_interne_bas.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      opacity: 1;
      display: block;
      position: relative;
      width: 100%;
      height: 440px;
    }
  }
  .planNiveau_numero_2 {
    opacity: 1;
    position: absolute;
    background-image: url("../assets/plan_batiment_interne_haut.png");
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
    width: 100%;
    height: 440px;
    background-position: center;
    bottom: 0;
  }
  .showNiveau2 {
    opacity: 0;
  }

  .containShowroomLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .MuiTypography-root.link1,
  .MuiTypography-root.link2,
  .MuiTypography-root.link3,
  .MuiTypography-root.link4 {
    font-size: 28px;
    text-decoration: underline;
    color: $black;
    position: relative;
    span {
      color: $black;
      display: block;
      text-transform: uppercase;
      font-size: 10px;
    }
    &::before {
      content: "";
      clear: both;
      display: block;
      position: absolute;
    }
    &::after {
      content: "";
      clear: both;
      display: block;
      position: absolute;
    }
  }

  .showroomLinkPlanNiveau_numero_1 {
    opacity: 0.5;
  }

  .link1 {
    &::after {
      background-image: url(../assets/line_vestiaire.svg);
      height: 64px;
      width: 75px;
      background-repeat: no-repeat;
      top: 50px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
  .showroomLink2 {
    position: absolute;
    top: 120%;
    right: 10%;
  }
  .link2 {
    &::after {
      background-image: url(../assets/line_mobilier.svg);
      height: 142px;
      width: 144px;
      background-repeat: no-repeat;
      top: 50px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
  .link3 {
    &::before {
      background-image: url(../assets/line_agencement.svg);
      height: 72px;
      width: 136px;
      background-repeat: no-repeat;
      bottom: 60px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
  .showroomLink3 {
    position: absolute;
    bottom: 80%;
    left: 42%;
  }
  .showroomLink4 {
    position: absolute;
    bottom: -140px;
    right: 10%;
  }
  .link4 {
    &::before {
      background-image: url(../assets/line_accueil.svg);
      height: 64px;
      width: 75px;
      background-repeat: no-repeat;
      bottom: 60px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
}
