.inspirationBanner {
  width: 100%;
  .inspirationBannerPrimary {
    display: flex;
    height: 690px;
    background: rgb(238, 245, 241);
    background: linear-gradient(
      180deg,
      rgba(238, 245, 241, 1) 0%,
      rgba(238, 245, 241, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    // background-color: $color-2;
    position: relative;
  }
  .inspirationBannerLeftContainer {
    justify-content: flex-end;
  }
  .inspirationBannerLeft {
    height: 690px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    span {
      margin-bottom: 10px;
    }
    h1 {
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 25px;
    }
    div {
      button {
        span {
          margin: 0 0 0 10px;
        }
      }
    }
    .w50 {
      width: 75%;
    }
    .inspirationBannerAddToFavContainer {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    // .inspirationBannerAddToFav {
    //   // position: absolute;
    //   // top: 10px;
    //   // right: 24px;
    // }
  }
  .inspirationBannerRight {
    max-height: 100%;
    height: 690px;
    width: 100%;
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
    }
    .swiper {
      position: absolute;
      width: 50%;
      height: 100%;
      max-height: 690px;
    }
  }
  .inspirationBannerSecondary {
    display: flex;
    justify-content: space-evenly;
    padding: 50px 0;
    div {
      text-align: center;
      p:nth-child(1) {
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 10px;
      }
      p:nth-child(2) {
        color: $light-grey;
      }
    }
  }
}
.moreImageInspiration {
  img {
    width: 100%;
  }
}
.inspirationInfoPratiqueBox {
  display: flex;
  padding: 50px 0;
  justify-content: center;
  .inspirationInfoPratique {
    width: 70%;
    p {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}
.inspirationApprocheBudget {
  display: flex;
  align-items: center;
  height: 620px;
  .inspirationApprocheBudgetLeft {
    width: 50%;
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-top-right-radius: 20px;
    }
  }
  .inspirationApprocheBudgetRight {
    width: 45%;
    padding-left: 70px;
    h3 {
      margin-bottom: 20px;
    }
    .inspirationApprocheBudgetList {
      ul {
        border-bottom: 1px solid $light-grey;
        list-style: none;
        padding-left: 0;
        li {
          margin-bottom: 20px;
          .inspirationApprocheBudgetTop {
            display: flex;
            justify-content: space-between;
            p:nth-child(1) {
              font-weight: bold;
              display: flex;
              align-items: center;
              font-family: $HB !important;
              color: $black;
            }
            span {
              margin-right: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              width: 20px;
              height: 20px;
              border-radius: 50px;
              border: 1px solid $color-1;
            }
          }
          .inspirationApprocheBudgetBottom {
            p {
              margin-top: 8px;
            }
          }
        }
      }
    }
    .inspirationApprocheBudgetTotal {
      padding-top: 20px;
      div {
        display: flex;
        justify-content: space-between;
        p {
          color: $black;
        }
        p:nth-child(1) {
          font-weight: bold;
        }
      }
      p {
        color: $light-grey;
      }
    }
  }
}
.inspirationDevis {
  background: rgb(238, 245, 241);
  background: linear-gradient(
    360deg,
    rgba(238, 245, 241, 1) 0%,
    rgba(238, 245, 241, 1) 70%,
    rgba(255, 255, 255, 1) 100%
  );
  display: flex;
  justify-content: center;
  .subFooterIndex {
    p {
      margin-bottom: 24px;
    }
  }
}
