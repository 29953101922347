.searchProductBarBox {
  width: 100%;
  .searchProductBarInput {
    width: 100%;
    height: 40px;
    padding: 0 25px;
    background-color: #F5F6F6;
    z-index: 10;
    font-size: 16px;
    color: $black;
    border-radius: 7px;
    transition: box-shadow 0.2s ease-in-out;
    position: relative;
    &::placeholder{
      font-size: 14px;
      font-family: $HR;
      color: rgba(0,0,0, .33);
    }
  }
  .searchProductResult {
    width: 100%;
    max-height: 200px;
    ul {
      max-height: 200px;
      margin-top: 10px;
      list-style: none;
      padding: 0;
      overflow-y: scroll;
      li {
        padding: 10px 15px;
        margin-top: 5px;
        position: relative;
        &:hover {
          background-color: $color-2;
          border-radius: 7px;
        }
        a {
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          position: absolute;
          color: $black;
        }
      }
    }
  }
}
