.homeContainer {
  width: 100%;
  .containGridHome {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 768px;
  }
  .homePresentationBanner {
    position: relative;
    background: linear-gradient(
      180deg,
      rgba(238, 245, 241, 1) 0%,
      rgba(238, 245, 241, 1) 90%,
      rgba(255, 255, 255, 1) 100%
    );
    // background-color: $color-2;
    height: 100%;
    max-height: 100%;
    min-height: 768px;
  }
  .homePresentationBannerTopInfoText {
    position: relative;
    z-index: 2;
    h1 {
      margin-top: 24px;
      margin-bottom: 40px;
      span {
        font-size: 48px;
        font-family: $P22;
      }
    }
    .homePresentationBannerSubInfo {
      display: flex;
      align-items: center;
      margin-top: 32px;
      .whoAreWeBtn {
        height: 40px;
        display: flex;
        align-items: center;
        margin-left: 32px;
        cursor: pointer;
        border-radius: 50px;
        padding-right: 10px;
        &:hover {
          background-color: $color-3;
        }
        p {
          text-transform: uppercase;
          font-size: 14px;
          font-family: $HR;
          margin-left: 10px;
        }
        .playIcon {
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 0px 6px #0000001a;
          border: solid 7px #00833d60;
          border-radius: 50%;
          img {
            height: 40%;
            margin-left: 2px;
          }
        }
      }
    }
  }
  .homePresentationBannerTopCarrousel {
    width: 45%;
    height: 100%;
    max-height: 100%;
    min-height: 768px;
    position: absolute;
    top: 40%;
    right: 0;
    overflow: hidden;
    transform: translate(0%, -30%); /* décalage de 50% de sa propre taille */
    .swiper {
      height: 520px;
    }
    .swiper-wrapper {
      align-items: center;
    }
    .swiper-slide {
      height: 420px;
      border-radius: 16px;
      img {
        border-radius: 16px;
      }
      &.swiper-slide-active {
        height: 520px;
        width: 420px !important;
      }
    }

    .swiper-pagination {
      left: auto;
      right: 30px;
      width: auto;
      background-color: rgba($white, 0.4);
      margin: 0;
      border-radius: 50px;
      display: flex;
      padding: 8px;

      .swiper-pagination-bullet {
        height: 12px;
        width: 12px;
        margin-bottom: 0;
        &.swiper-pagination-bullet-active {
          background: $color-1;
        }
      }
    }
  }
  // .homePresentationBannerTopCarrousel {
  //   width: 40%;
  //   height: 50vh;
  //   min-height: 681px;
  //   max-height: 1024px;
  //   position: relative;
  //   overflow: hidden;
  //   .pinsPopupOverlay {
  //     opacity: 0;
  //     pointer-events: none;
  //     background-color: rgba(0, 0, 0, 0.479);
  //     height: 100%;
  //     width: 100%;
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     justify-content: center;
  //     z-index: 15;
  //     cursor: pointer;
  //     .pinsPopup {
  //       cursor: auto;
  //       background-color: $white;
  //       height: 260px;
  //       width: 210px;
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;
  //       .pinsPopupHeader {
  //         width: 100%;
  //         height: 26px;
  //         display: flex;
  //         align-items: center;
  //         justify-content: flex-end;
  //         padding: 5px 5px 0 0;
  //         button {
  //           cursor: pointer;
  //           padding: 0;
  //         }
  //       }
  //       .pinsPopupContent {
  //         width: 100%;
  //         height: 169px;
  //         display: flex;
  //         flex-direction: column;
  //         align-items: center;
  //         justify-content: center;
  //         .pinsPopupContentTitle {
  //           width: 90%;
  //           height: 67px;
  //           display: flex;
  //           align-items: center;
  //           justify-content: center;
  //         }
  //         .pinsPopupContentImg {
  //           width: 90%;
  //           height: 100px;
  //           object-fit: contain;
  //           position: relative;
  //           top: 0;
  //           left: 0;
  //           transform: none;
  //         }
  //       }
  //       .pinsPopupAction {
  //         width: 90%;
  //         height: 65px;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         a {
  //           background-color: $black;
  //           color: $white;
  //           width: 100%;
  //           height: 60%;
  //           border-radius: 7px;
  //           display: flex;
  //           align-items: center;
  //           justify-content: center;
  //         }
  //       }
  //     }
  //   }
  //   .onPopup {
  //     animation: onPopup 0.8s 0s ease-in forwards;
  //   }
  //   .homePresentationBannerTopCarrouselImgBox {
  //     height: 100%;
  //     width: 100%;
  //     position: absolute;
  //     .pinsCanvas {
  //       position: relative;
  //       height: 100%;
  //       width: 100%;
  //       .pins {
  //         position: absolute;
  //         background-color: $white;
  //         height: 30px;
  //         width: 30px;
  //         border-radius: 50%;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         outline: solid 7px rgba(255, 255, 255, 0.267);
  //         transition: outline 0.3s;
  //         &:hover {
  //           outline: solid 8px $color-1;
  //         }
  //       }
  //     }
  //   }
  //   img {
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     height: 100%;
  //     transform: translate(-50%, -50%);
  //   }
  //   .swiper-pagination {
  //     left: auto;
  //     right: 30px;
  //     width: auto;
  //     background-color: rgba($white, 0.4);
  //     margin: 0;
  //     border-radius: 50px;
  //     display: flex;
  //     padding: 8px;

  //     .swiper-pagination-bullet {
  //       height: 12px;
  //       width: 12px;
  //       margin-bottom: 0;
  //       &.swiper-pagination-bullet-active {
  //         background: $color-1;
  //       }
  //     }
  //   }
  // }
}

@keyframes onPopup {
  from {
    opacity: 0;
    pointer-events: none;
  }
  50% {
    opacity: 100%;
    pointer-events: none;
  }
  to {
    opacity: 100%;
    pointer-events: all;
  }
}
