.associateProductsContainer {
  width: 100%;
  margin-top: 50px;
  .associateProductsHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .associateProductsLink {
    margin: 8px 0;
    display: block;
    span {
      text-decoration: none;
    }
  }
}
.associateProductsCardInner {
  max-height: 100%;
  width: 100%;
  border-radius: 7px;
  cursor: pointer;
  padding: 20px;
  text-align: center;
  section {
    width: 100%;
    height: 240px;
    background-color: $color-3;
  }
  img {
    width: 100%;
    height: 385px;
    max-height: 100%;
    object-fit: contain;
    margin-bottom: 16px;
  }
  span {
    text-transform: uppercase;
    font-size: 14px;
  }
  p {
    margin-top: 16px;
    font-family: $HB !important;
  }
  &:hover {
    box-shadow: 1px 1px 5px #00000014;
  }
}
