.SRContainer {
  .SRIntroBox {
    display: flex;
    // justify-content: space-around;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    background: $color-3;
    background: linear-gradient(
      180deg,
      rgb(238, 245, 241) 0%,
      rgb(238, 245, 241) 50%,
      rgb(255, 255, 255) 100%
    );
    .SRIntro {
      width: 35%;
      p:nth-child(2) {
        font-size: 24px !important;
        font-family: $HB !important;
        margin-top: 40px;
      }
      p:nth-child(3) {
        margin-top: 20px;
      }
      p:nth-child(4) {
        margin-top: 20px;
      }
      p {
        span {
          font-family: $HB !important;
          color: $black;
          letter-spacing: 0;
        }
      }
      ul {
        margin-bottom: 30px;
        li {
          list-style: none;
          span {
            font-family: $HB !important;
            color: $black;
            letter-spacing: 0;
          }
        }
      }
      img {
        width: 100%;
        height: 380px;
        object-fit: cover;
      }
    }
    .SRIntroImg {
      width: 35%;
      height: 416px;
      position: relative;
      // div:nth-child(1) {
      //   background-image: url(../assets/gondole-sr-evolutif.jpg);
      //   background-position: center;
      //   background-size: contain;
      //   background-repeat: no-repeat;
      //   // background-color: yellow;
      //   width: 153px;
      //   height: 199px;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   z-index: 503;
      // }
      // div:nth-child(2) {
      //   background-image: url(../assets/logo-sr-evolutif.png);
      //   background-position: center;
      //   background-size: contain;
      //   background-repeat: no-repeat;
      //   // background-color: blue;
      //   width: 153px;
      //   height: 199px;
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   z-index: 502;
      // }
      div:nth-child(3) {
        background-image: url(../assets/logo-sr-evolutif.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        // background-color: pink;
        width: 319px;
        height: 319px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 500;
      }
      // div:nth-child(4) {
      //   // background-color: green;
      //   background-image: url(../assets/equerre_sr_l_evolutif_3.png);
      //   background-position: center;
      //   background-size: contain;
      //   background-repeat: no-repeat;
      //   width: 260px;
      //   height: 199px;
      //   position: absolute;
      //   bottom: 0;
      //   right: 0;
      //   z-index: 501;
      // }
    }
  }
  .SRConception {
    max-width: 1526px;
    margin: 0 auto;
    height: 100%;
    padding-bottom: 96px;
    // padding: 70px 15px;
    display: flex;
    align-items: center;
    display: flex;
    .SRConceptionLeft {
      width: 50%;
      height: 100%;
      max-height: 1080px;
      display: flex;
      justify-content: center;
      position: relative;
      img {
        width: 100%;
        height: auto;
        // height: 100%;
      }
      .content-img_gondoleSr {
        height: 100%;
        max-height: 1080px;
        position: relative;
        width: 100%;
      }
      .numberGondoleSr {
        background-color: $color-4;
        border: 2px solid $color-1;
        position: absolute;
        width: 22px;
        height: 22px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        font-weight: 800;
        font-family: $HB;
        cursor: pointer;
        color: $color-1;
        &:hover {
          width: 30px;
          height: 30px;
          background-color: $color-1;
          color: #fff;
        }
        &.pinNumber1 {
          top: 6%;
          left: 13%;
        }
        &.pinNumber2 {
          top: 9%;
          left: 21%;
        }
        &.pinNumber3 {
          top: 15%;
          left: 15%;
        }
        &.pinNumber4 {
          top: 24%;
          left: 50%;
        }
        &.pinNumber5 {
          top: 30%;
          left: 20%;
        }
        &.pinNumber6 {
          top: 40%;
          left: 55%;
        }
        &.pinNumber7 {
          bottom: 37%;
          top: auto;
          left: auto;
          right: 18%;
        }
        &.pinNumber8 {
          bottom: 34%;
          top: auto;
          left: 35%;
        }
        &.pinNumber9 {
          bottom: 28%;
          top: auto;
          left: 55%;
        }
        &.pinNumber10 {
          bottom: 16%;
          top: auto;
          left: 50%;
        }
        &.pinNumber11 {
          bottom: 14%;
          top: auto;
          left: 12%;
        }
        &.pinNumber12 {
          bottom: 9%;
          top: auto;
          left: 20%;
        }
        &.pinNumber13 {
          bottom: 8%;
          top: auto;
          left: 29%;
        }
        &.pinNumber14 {
          bottom: 6%;
          top: auto;
          left: 65%;
        }
      }
    }
    .SRConceptionRight {
      width: 50%;
      height: 100%;
      div {
        width: 80%;
        margin: 0 auto;
        ul {
          list-style: none;
          padding: 0;
        }
      }
      .SRConceptionRightPres {
        p {
          padding: 20px 0 10px 0;
        }
      }
      .SRConceptionRightDl {
        padding: 40px 0;
      }
      .SRConceptionRightNotice {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.468);
        border-radius: 16px;
        display: flex;
        justify-content: space-between;
        padding: 30px;
        div {
          width: 50%;
        }
        b {
          font-weight: bolder;
          color: $color-1;
          font-size: 20px;
        }
      }
    }
    .SRConceptionCitation {
      height: 400px;
      background-color: red;
      position: relative;
      display: flex;
      justify-content: center;
      img {
        height: 80px;
        position: absolute;
      }
      p {
        width: 60%;
        font-weight: bold;
        font-style: italic;
        margin-bottom: 10px;
        font-size: 30px;
        text-align: center;
      }
    }
  }
}
