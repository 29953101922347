.noPageContainer {
  height: 70vh;
  display: flex;
  align-self: center;
  justify-content: center;
  .noPageBox {
    width: 85%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 90px;
      color: #ffffff;
      text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191,
        1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191,
        1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191,
        1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4),
        1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
        1px 30px 60px rgba(16, 16, 16, 0.4);
    }
  }
}
