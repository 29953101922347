.sousUniverBanner {
  height: 690px;
  // display: flex;
  background: rgb(238, 245, 241);
  background: linear-gradient(
    180deg,
    rgba(238, 245, 241, 1) 0%,
    rgba(238, 245, 241, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  position: relative;
  .sousUniverBannerLeft {
    height: 690px;
    width: 100%;
    padding-right: 96px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    span {
      display: block;
      margin-bottom: 24px;
    }
    h1 {
      margin-bottom: 24px;
    }
    p {
      margin-bottom: 25px;
    }
    .containBannerAddToFav{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .sousUniverBannerRight {
    max-height: 100%;
    height: 600px;
    width: 100%;
    img {
      height: 100%;
      width: 50vw;
      // width: 100%;
      object-fit: cover;
      // position: absolute;
    }
  }
}
.sousUniverDescription {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sousUniverPics {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 260px;
  }
}
.sousUniverGridImages {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  div {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    img {
      height: 260px;
      width: 100%;
      object-fit: cover;
    }
  }
}

.associateUniverProductsContainer {
  display: flex;
  margin-top: 70px;
  margin-bottom: 70px;
  .associateUniverProductsHeader {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      width: 70%;
    }
  }
}
