.reg {
  background-color: $color-1;
  color: $white;
  color: #eafbe7;
  color: #c6f0c2;
  color: #5cb176;
  color: #328048;
  color: #2f6846;
}
.faqContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  .faqSearchBar {
    width: 80%;
    position: relative;
    z-index: 10;
    margin-bottom: 30px;
    background-color: #ffffff;
    -webkit-user-select: none;
    user-select: none;
    padding: 20px;
    box-shadow: 0px 10px 10px rgb(0 0 0 / 5%);
    border-radius: 10px;
    .searchfaqBarBox {
      width: 100%;
      .searchfaqBarInput {
        width: 100%;
        height: 40px;
        padding: 0 25px;
        background-color: #f5f6f6;
        z-index: 10;
        font-size: 16px;
        color: $black;
        border-radius: 7px;
        transition: box-shadow 0.2s ease-in-out;
        position: relative;
        &::placeholder {
          font-size: 14px;
          font-family: $HR;
          color: rgba(0, 0, 0, 0.33);
        }
      }
    }
  }
  .faqBox {
    width: 70%;
    margin: 20px 0;
    h3 {
      margin-bottom: 20px;
    }
    .MuiPaper-root {
      box-shadow: none;
      border: none;
    }
    .MuiAccordionSummary-root {
      height: 60px;
      border-bottom: 1px solid #f4f4f4;
      h3 {
        font-family: $HB !important;
        font-size: 18px;
      }
    }
    p.css-ir18gk-MuiTypography-root {
      font-size: 16px;
      line-height: 26px;
      a{
        padding-top: 24px;
        display: block;
        text-decoration: underline;
        color: $black;
      }
    }
  }
}
