.NoticesTutosContainer {
  .NoticesTutosSearchBar {
    width: 100%;
    position: relative;
    z-index: 10;
    margin-bottom: 30px;
    background-color: #ffffff;
    -webkit-user-select: none;
    user-select: none;
    padding: 20px;
    box-shadow: 0px 10px 10px rgb(0 0 0 / 5%);
    border-radius: 10px;
    .searchNoticesTutosBarBox {
      width: 100%;
      .searchNoticesTutosBarInput {
        width: 100%;
        height: 40px;
        padding: 0 25px;
        background-color: #f5f6f6;
        z-index: 10;
        font-size: 16px;
        color: $black;
        border-radius: 7px;
        transition: box-shadow 0.2s ease-in-out;
        position: relative;
        &::placeholder {
          font-size: 14px;
          font-family: $HR;
          color: rgba(0, 0, 0, 0.33);
        }
      }
    }
    section {
      display: flex;
      justify-content: center;
      align-items: center;
      .containerBtnUniversCategories {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btnUniversFiltersSearch {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        color: $light-grey;
        cursor: pointer;
        img.imgUniversIcon {
          height: 24px;
        }
        img {
          height: 30px;
        }
        div {
          display: flex;
          p {
            font-size: 14px;
            display: flex;
            span {
              font-size: 13px;
            }
          }
          svg {
            fill: $light-grey;
          }
          .turnArrow {
            transform: rotate(180deg) !important;
          }
        }
      }
    }
    .universFilterList {
      background-color: $white;
      width: 100%;
      z-index: 10;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-gap: 10px;
      grid-auto-rows: minmax(100px, auto);
      height: 0px;
      overflow: hidden;
      transition: height 0.3s;
      .universFilterListCard {
        height: 90px;
        margin: 8px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background-color: #f9f7f4;
          border-radius: 8px;
        }
        picture {
          width: 40px;
          height: 40px;
          border-radius: 100px;
          align-items: center;
          justify-content: center;
          display: flex;
          position: relative;
          z-index: 9;
        }
        img {
          height: 24px;
        }
        p {
          margin-top: 8px;
          font-size: 10px;
          text-align: center;
          text-transform: uppercase;
          font-weight: 600;
        }
      }
    }
    .universFilterListOpen {
      height: 100px;
    }
    .wrapperUniversFilter {
      display: flex;
      align-items: center;
    }
  }
  .NoticesTypeBox {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 100px 0 100px 80px;
    h3 {
      position: absolute;
      left: -100px;
      top: 46%;
      transform: rotate(-90deg);
      font-size: 24px;
    }
    .NoticesGrid {
      width: 100%;
      div {
        display: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-bottom: 30px;
        a {
          display: block;
          position: relative;
        }
        .tutoPlayerIcon {
          background-color: $color-1;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin: 0 auto;
        }
        img {
          height: 250px;
          object-fit: contain;
          width: 100%;
          max-width: 320px;
        }
        span {
          margin-top: 16px;
          margin-bottom: 8px;
        }
        p {
          max-width: 320px;
          text-align: center;
        }
      }
    }
    &.NoticesTypeBoxTuto {
      h3 {
        left: 0;
      }
    }
  }
}
.videoIframeOverlay {
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.701);
  height: 100%;
  width: 100%;
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
  iframe {
    height: 80%;
    width: 80%;
  }
  .closeVideoIframe {
    position: absolute;
    height: 50px;
    width: 50px;
    background-color: $color-1;
    border-radius: 50%;
    top: 8%;
    right: 9%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.showIframe {
  opacity: 100%;
  pointer-events: all;
}
