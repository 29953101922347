.distributeurContainer {
  height: 100vh;
  max-height: 100%;
  min-height: 641px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  .distributeurBox {
    .h100 {
      // min-height: 1024px;
    }
    .distributeurForm {
      .MuiGrid-grid-lg-8 {
        display: flex;
        align-items: center;
        &::before {
          background-image: url("../assets/structure_tilt.svg");
          content: "";
          width: 100%;
          height: 90%;
          display: block;
          background-repeat: no-repeat;
          position: absolute;
          left: 0;
          bottom: 0;
          background-size: contain;
          z-index: -1;
          // min-height: 1024px;
        }
        &::after {
          background: $color-2;
          content: "";
          width: 60%;
          height: 100%;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background-size: contain;
          z-index: -2;
          // min-height: 1024px;
        }
      }
      .MuiGrid-grid-lg-4 {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
      }
      .gridContainerConnexionInformation {
        h1 {
          margin-top: 24px;
        }
        p {
          max-width: 80%;
        }
      }
      .gridContainerConnexion {
        padding: 24px 0;
        background-color: $white;
        text-align: center;
        h2 {
          margin-bottom: 24px;
        }
      }
      .loginBox {
        // height: 91vh;
        // min-height: 1024px;
      }
      form {
        p {
          margin-top: 10px;
          max-width: 420px;
        }
        input {
          background-color: $color-4;
          padding: 15px 15px;
          font-size: 16px;
          border-radius: 7px;
          margin-top: 15px;
          width: 320px;
          &::placeholder {
            color: $black;
          }
        }

        input[type="submit"],
        input[type="button"] {
          text-transform: none;
          height: auto;
          margin-top: 0;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
          color: $white;
          font-family: $HR;
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 1.5px;
          background-color: $darkGrey;
          cursor: pointer;
          width: 180px;
          text-align: left;
        }
        .inputButton {
          display: flex;
          align-items: center;
          margin-top: 20px;
          margin-left: auto;
          margin-right: auto;
          position: relative;
          width: 180px;
          span {
            position: absolute;
            right: 10px;
            transform: rotate(-90deg);
            color: $white;
          }
        }
      }
    }
  }
}
.distributeurContainerSigned {
  position: relative;
  .distributeurBox {
    .distributeurForm {
      .gridContainerConnexionInformation {
        h1 {
          margin-top: 24px;
        }
        p {
          max-width: 80%;
        }
      }
      .gridContainerConnexion {
        background-color: $white;
        text-align: center;
        h2 {
          margin-bottom: 24px;
        }
      }
      form {
        p {
          margin-top: 10px;
        }
        input {
          background-color: $color-4;
          padding: 15px 15px;
          font-size: 16px;
          border-radius: 7px;
          margin-top: 15px;
          &::placeholder {
            color: $black;
          }
        }

        input[type="submit"],
        input[type="button"] {
          text-transform: none;
          height: auto;
          margin-top: 0;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
          color: $white;
          font-family: $HR;
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 1.5px;
          background-color: $darkGrey;
          cursor: pointer;
          width: 200px;
          text-align: left;
        }
        .inputButton {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 20px;
          position: relative;
          width: 100%;
          span {
            position: absolute;
            right: 10px;
            transform: rotate(-90deg);
            color: $white;
          }
        }
      }
    }
    .cataloguesTypeBox {
      position: relative;
      h3 {
        font-size: 24px;
        margin-bottom: 64px;
      }
      .majp {
        font-size: 14px;
      }
      .cataloguesGrid {
        width: 100%;
        .MuiLink-root {
          padding-bottom: 48px;
          text-decoration: none;
          cursor: pointer;
          img {
            border: 2px solid $color-4;
            object-fit: contain;
            transition: all 0.3s ease-in;
            padding: 4px;
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        }
        div {
          display: inherit;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          img {
            height: 240px;
            max-width: 240px;
            object-fit: cover;
          }
          p {
            margin-top: 24px;
            text-align: left;
            color: $black;
          }
        }
      }
      &.cataloguesTypeBoxMiseAvant {
        .cataloguesGrid {
          img {
            height: 387px;
          }
        }
      }
    }
    .NoticesTypeBoxTuto {
      position: relative;
      // padding: 100px 0 100px;
      h3 {
        font-size: 24px;
        margin-bottom: 64px;
      }
      .NoticesGrid {
        width: 100%;
        div {
          display: inherit;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          cursor: pointer;
          margin-bottom: 30px;
          a {
            display: block;
            position: relative;
          }
          .tutoPlayerIcon {
            background-color: $color-1;
            width: 70px;
            height: 70px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
          }
          img {
            border: 2px solid $color-4;
            padding: 4px;
            height: 250px;
            max-width: 320px;
            object-fit: contain;
            width: 100%;
          }
          span {
            margin-top: 16px;
            margin-bottom: 8px;
          }
        }
      }
      // &.NoticesTypeBoxTuto {
      //   h3 {
      //     left: -0.5%;
      //   }
      // }
    }
  }
}
.infoMajCatalogue {
  max-width: 320px;
}

@keyframes fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
