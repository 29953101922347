.contactContainer {
  .contactForm {
    margin-top: 70px;
    margin-bottom: 70px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px 20px;
    .contactField {
      label {
        font-family: $HB !important;
        span {
          color: orangered;
          font-weight: bold;
        }
        span.MuiCheckbox-root {
          color: $color-1;
        }
        span.MuiTypography-root {
          color: $black;
          font-size: 16px;
        }
      }
      input,
      textarea,
      select {
        margin-top: 10px;
        background-color: #f5f6f6;
        width: 100%;
        height: 50px;
        border-radius: 8px;
        font-size: 20px;
        padding: 0 20px;
        &:focus {
          border: 1px solid $color-1;
        }
      }
      textarea {
        height: 240px;
        resize: none;
        padding: 20px 20px;
      }
      &:nth-child(1) {
        grid-column: 1 / 3;
      }
      &:nth-child(7) {
        grid-column: 1 / 3;
      }
      &:nth-child(10) {
        grid-column: 1 / 3;
      }
      &:nth-child(11) {
        grid-column: 1 / 3;
      }
      &:nth-child(12) {
        grid-column: 1 / 3;
      }
      &:nth-child(13) {
        grid-column: 1 / 3;
      }
      &:nth-child(14) {
        grid-column: 1 / 3;
      }
      &:nth-child(15) {
        grid-column: 1 / 3;
      }
      .contactCheckBox {
        display: flex;
        height: 20px;
        margin-top: 15px;
        input {
          width: 20px;
          height: 20px;
          margin: 0px 10px 0 10px;
          cursor: pointer;
          accent-color: $color-1;
        }
        label {
          font-family: $HR !important;
        }
      }
      .devisRadio {
        display: flex;
        height: 20px;
        margin-top: 15px;
        div {
          display: flex;
          align-items: center;
          input {
            width: 20px;
            height: 20px;
            margin: 0px 10px 0 10px;
            cursor: pointer;
            accent-color: $color-1;
          }
          label {
            font-weight: 100 !important;
          }
        }
      }

      input[type="submit"] {
        text-transform: none;
        height: 40px;
        margin-top: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        color: $white;
        font-family: $HR;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 1.5px;
        background-color: $darkGrey;
        cursor: pointer;
        width: 260px;
        text-align: left;
      }
    }
    #contactFieldSubmit {
      display: flex;
      align-items: center;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      width: 260px;
      span {
        position: absolute;
        right: 10px;
        transform: rotate(-90deg);
        color: $white;
      }
    }
  }
}
