.proposeProductsContainer {
  h2 {
    margin-bottom: 40px;
    span {
      a {
        color: $color-1;
        font-size: 38px;
        font-family: $P22;
      }
    }
    a {
      font-size: 38px;
      font-family: $P22;
    }
  }

  .proposeProductSwiper {
    .swiper-button-prev,
    .swiper-button-next {
      height: 35px;
      width: 35px;
      background-color: $white;
      box-shadow: 1px 1px 10px #00000014;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px !important;
      cursor: pointer;
    }
    .swiper-button-prev {
      left: 0;
      &:hover {
        box-shadow: 1px 1px 5px #00000014;
      }
      &::after {
        content: "";
        background-repeat: no-repeat;
        background-image: url("../assets/arrow.svg");
        font-family: serif;
        width: 9px;
        height: 15px;
      }
    }
    .swiper-button-next {
      right: 0;
      &::after {
        content: "";
        background-repeat: no-repeat;
        background-image: url("../assets/arrow.svg");
        font-family: serif;
        width: 9px;
        height: 15px;
        transform: rotate(180deg);
      }
    }
  }

  .swiperSlide.proposeProductsCardCase {
    margin-left: 20px !important;
  }
  .proposeProductsLink {
    margin: 8px 0;
    height: auto;
    display: block;
    span {
      text-decoration: none;
    }
    .proposeProductsCard {
      max-height: 100%;
      height: auto;
      width: 100%;
      border-radius: 7px;
      cursor: pointer;
      padding: 20px;
      text-align: center;
      img {
        width: 100%;
        height: 216px;
        max-height: 100%;
        object-fit: contain;
      }
      span {
        text-transform: uppercase;
        font-size: 14px;
      }
      p {
        margin-top: 16px;
        font-family: $HB !important;
      }
      &:hover {
        box-shadow: 1px 1px 5px #00000014;
      }
    }
  }

  .proposeProductsShowMore {
    &:hover {
      &:before {
        content: "";
        margin-right: 0;
        opacity: 0;
        animation: showArrow 0.2s forwards;
      }
    }
  }
}

@keyframes showArrow {
  from {
    content: "";
    margin-right: 0px;
    opacity: 0;
    display: none;
  }
  50% {
    content: "\2192";
    margin-right: 0px;
    opacity: 0;
    display: block;
  }
  to {
    content: "\2192";
    margin-right: 8px;
    opacity: 100%;
    display: block;
  }
}
