.emploiesContainer {
  display: flex;
  justify-content: center;
  .emploiesBox {
    .emploiesFilter {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .emploiesFilterCategory {
        width: 40%;
        p {
          font-family: $HB !important;
          margin-bottom: 10px;
        }
        select {
          width: 100%;
          border: 2px solid $color-1;
          height: 55px;
          border-radius: 8px;
          padding: 0 10px;
          font-size: 16px;
          cursor: pointer;
          option {
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
      .emploiesFilterContract {
        display: flex;
        align-items: flex-end;
        .emploiesFilterContractPins {
          border: 2px solid $black;
          font-family: $HB !important;
          height: 55px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 20px;
          border-radius: 50px;
          margin-left: 15px;
          transition: 0.2s;
          cursor: pointer;
          &:hover {
            border: 2px solid $color-1;
            color: $color-1;
          }
        }
        .selectedContract {
          background-color: $color-1;
          border: 2px solid $color-1;
          color: $white;
          &:hover {
            border: 2px solid $color-1;
            color: $white;
          }
        }
      }
    }
    .emploiesList {
      border-top: 2px solid rgb(240, 240, 240);
      .emploiesCard {
        border-bottom: 2px solid rgb(240, 240, 240);
        padding: 25px 0;
        .emploiesTitle {
          font-size: 24px;
          font-family: $HB !important;
        }
        .emploiesContracrtAndLocation {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          .emploiesContract {
            font-family: $HB !important;
            text-transform: uppercase;
          }
          .emploiesLocation {
            font-family: $HB !important;
            text-transform: uppercase;
          }
        }
        .emploiesDate {
          margin-top: 20px;
          font-size: 12px;
        }
      }
    }
  }
}

.emploieContainer {
  .emploieBox {
    p {
      margin-bottom: 40px;
    }
    .emploieBtn {
      display: flex;
      justify-content: center;
    }
  }
}

.mainCandidature{
  .banner{
    height: 380px;
  }
}