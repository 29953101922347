.searchBox {
  width: 100%;
  display: flex;
  align-items: flex-end;
  .searchBoxIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(239, 239, 239);
    border-radius: 7px;
    width: 100%;
    height: 40px;
    min-width: 40px;
    svg.MuiSvgIcon-root {
      padding-left: 8px;
      padding-right: 8px;
      width: 38px;
    }
  }
  input {
    width: 100%;
    height: 40px;
    font-size: 1rem;
    background-color: rgb(239, 239, 239);
    z-index: 10;
    border-radius: 7px;
    transition: box-shadow 0.2s ease-in-out;
    position: relative;
    font-size: 14px;
    padding-left: 8px;
  }

  .onInput {
    box-shadow: 0px 1px 2px rgb(149, 149, 149);
  }
  .searchSuggest {
    background-color: rgb(239, 239, 239);
    position: absolute;
    top: 35px;
    width: 100%;
    padding: 20px 15px 5px 15px;
    z-index: 5;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    box-shadow: 0px 1px 2px rgb(149, 149, 149);
    height: 0;
    max-height: 200px;
    p {
      margin-bottom: 10px;
      opacity: 0%;
    }
  }
  .resultOverlay {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    opacity: 0%;
    pointer-events: none;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    .resultBox {
      cursor: auto;
      background-color: $white;
      box-shadow: 0 300px 200px rgba(0, 0, 0, 0.28);
      width: 100%;
      height: 75%;
      margin-top: 98px;
      display: flex;
      flex-direction: column;
      .rapidAccess {
        padding: 40px 38px 15px 40px;
        display: flex;
        align-items: center;
        p {
          margin-right: 10px;
        }
        a {
          border: 1px solid $color-1;
          padding: 5px 7px;
          font-size: 14px;
          border-radius: 15px;
          margin-right: 10px;
          background-color: transparent;
          &:hover{
            background-color: $color-3;
          }
        }
      }
      .resultList {
        max-width: 1526px;
        margin: 0 auto;
        padding: 0 24px;
        display: flex;
        overflow-y: scroll;
        // justify-content: space-between;
        height: 100%;
        overflow-y: auto;
        .resultCard {
          padding: 0 10px 0 15px;
          display: flex;
          flex-direction: column;
          &:last-child {
            border-right: none;
          }
          .resultCardTitle {
            color: $color-1;
            text-decoration: underline;
            padding: 16px 0 8px 0;
            border-bottom: 1px solid rgba(128, 128, 128, 0.15);
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 10px;
            line-height: 24px;
            letter-spacing: 1.2px;
          }
          p {
            padding-top: 16px;
            margin-bottom: 10px;
            font-family: $HB;
          }
          a {
            font-size: 14px;
            cursor: pointer;
            margin-bottom: 0;
            height: auto;
            padding: 5px 7px;
            &:hover {
              background-color: $color-3;
              border-radius: 5px;
            }
            .resultCardRef {
              color: $light-grey;
              margin-bottom: 0px;
              font-family: $HB;
              font-size: 14px;
              padding-top: 0;
            }
            .resultCardNom {
              padding-top: 0;
              margin-bottom: 0px;
              font-family: $HB;
            }
          }
        }
      }
      .resultListTypeproducts {
        border-right: 1px solid rgba(128, 128, 128, 0.15);
      }
      .resultListReaInspi {
        border-left: 1px solid rgba(128, 128, 128, 0.15);
      }
      // padding: 20px 0 0px 0;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // .resultList {
      //   width: 90%;
      //   height: 85%;
      //   // display: grid;
      //   // grid-template-columns: repeat(6, minmax(min-content, 1fr));
      //   // grid-gap: 10px;
      //   display: flex;
      //   overflow-y: auto;

      // }
      .noResult {
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .showAllResult {
        width: 100%;
        margin: 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          background-color: $black;
          color: white;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
          padding: 10px 15px;
          cursor: pointer;
        }
      }
    }
  }
  .resultOverlayOpen {
    pointer-events: all;
    opacity: 100%;
  }
  .resultOverlayClose {
    pointer-events: none;
    opacity: 0%;
  }
}

.searchBoxActive {
  .aideContactMenu {
    display: none;
  }
  .searchBox {
    input {
      position: absolute;
      top: 30px;
      left: 0;
    }
  }
}
