.contain-footer {
  position: relative;
}
.footer {
  background-color: $darkGrey;
  user-select: none;
  position: relative;
  // position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  p,
  a {
    font-size: 14px;
    color: $white;
  }
  a:hover {
    color: $color-1;
  }
  ul,
  li {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
  }
  li {
    margin-bottom: 16px;
  }
  .container-grid-footer {
    padding-top: 32px;
  }
  .titleFooter {
    font-family: $HR;
    margin-bottom: 24px;
    margin-top: 24px;
    font-size: 16px;
  }
  .border-footer {
    border-top: 1px solid $grey;
  }
  .downFooter {
    padding-top: 16px;
    display: flex;
    width: 100%;
    padding-bottom: 24px;
  }
  .btnOutlinedFooter {
    // display: block;
    margin-bottom: 10px;
    width: auto;
    border-color: $white;
    text-transform: none;
    &:hover {
      border-color: $color-1;
      background-color: transparent;
    }
  }
  .reseau {
    margin-bottom: 20px;
    a:hover {
      svg {
        fill: $color-1;
      }
    }
  }
}
