.favorisContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  .favorisDevis {
    width: 70%;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid rgba(128, 128, 128, 0.164);
    padding-bottom: 20px;
    margin-bottom: 10px;
    a {
      color: $color-1;
      background-color: $color-2;
      padding: 10px 15px;
      cursor: pointer;
    }
  }
  .favorisBox {
    width: 70%;
    .favorisSection {
      padding: 15px 0;
      h3{
        margin-bottom: 20px;
      }
      .favorisCard {
        padding: 20px;
        a {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          img,
          picture {
            width: 100%;
            height: 250px;
            object-fit: contain;
            margin-bottom: 10px;
          }
          p {
            font-family: Hellix-Bold !important;
            font-size: 16px !important;
            line-height: 26px;
          }
          .favorisDeleteBtn {
            position: absolute;
            right: 0;
            cursor: pointer;
            svg.MuiSvgIcon-root {
              path {
                fill: #e52413;
              }
            }
          }
        }
        &:hover {
          box-shadow: 0 0 10px #00000014;
          border-radius: 16px;
        }
      }
    }
  }
}
