.lastActuText {
  padding-top: 96px;
  padding-right: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  .pretitre {
    padding-bottom: 16px;
  }
  h2 {
    padding-bottom: 24px;
  }
  .arrowButton {
    margin-top: 24px;
  }
  .containBannerAddToFav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
.lastActuImage {
  position: relative;
  width: 50vw;
  // max-height: 600px;
  img {
    // position: absolute;
    width: 100%;
    height: 600px;
    max-height: 100%;
    object-fit: cover;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  // &::after {
  //   content: "";
  //   display: block;
  //   padding-bottom: 100%;
  // }
}
.allActus {
  .filterActus {
    width: 100%;
    padding: 20px 0 24px;
    span {
      font-family: $HR;
      color: $black;
      display: block;
      margin-bottom: 8px;
      margin-left: 16px;
    }
    input {
      width: 50%;
      height: 40px;
      padding: 0 25px;
      font-size: 1rem;
      background-color: rgb(239, 239, 239);
      z-index: 10;
      border-radius: 7px;
      transition: box-shadow 0.2s ease-in-out;
      position: relative;
      font-size: 14px;
    }
  }
  .actuCard {
    width: 100%;
    position: relative;
    .actuCardImg {
      height: 350px;
      width: 100%;
      position: relative;
      img {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
      }
    }
    .actuCardText {
      font-weight: bold;
      padding: 10px 20px;
      .actuCardTextTitre {
        font-family: $P22 !important;
        font-size: 18px;
        margin-bottom: 10px;
      }
      .actuCardTextDate {
        margin-bottom: 10px;
        color: $light-grey;
      }
      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    a {
      &:hover {
        .actuCardImg {
          &::before {
            clear: both;
            content: "";
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            background-color: $black;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0.3;
          }
        }
        .pretitre {
          display: flex;
          align-items: center;
          &::before {
            content: "";
            background-image: url("../assets/horizontalRule.svg");
            width: 33px;
            height: 2px;
            display: block;
            position: relative;
            margin-right: 8px;
          }
        }
      }
    }
    .bannerAddToFav {
      background-color: white;
      position: absolute;
      top: 24px;
      border-radius: 4px;
      right: 8px;
      z-index: 2;
    }
  }
  .paginationActualites {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 50px;
  }
}

// actu individuelle
.actualiteContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  .actualiteDate {
    color: $color-1;
    font-weight: bold;
    margin-bottom: 30px;
    position: relative;
    .actualiteAddToFav {
      position: absolute;
      top: 0px;
      right: 10px;
    }
  }
  .actualiteDescription {
    margin-bottom: 40px;
    p {
      margin-bottom: 24px;
    }
    h3 {
      font-family: $HR !important;
      font-size: 24px;
      margin-bottom: 24px;
    }
    a {
      color: $color-1 !important;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  .actualiteImage {
    margin-bottom: 40px;
    width: 100%;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}
