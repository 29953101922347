.productsCategories {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .productsCategoriesBox {
    .css-knus0w-MuiGrid-root {
      .css-11l5t4l-MuiGrid-root {
        &:last-child {
          .productsCategoriesCard {
            .productsCategoriesCardImage {
              img {
                padding: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
    .productsCategoriesCard {
      padding-bottom: 56px;
      cursor: pointer;
      .productsCategoriesCardImage {
        margin-bottom: 15px;
        background-color: #f9f7f4;
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 70%;
          width: auto;
          object-fit: contain;
        }
      }
      .productsCategoriesCardTitle {
        font-family: $HB !important;
        color: $black !important;
        margin-bottom: 15px;
      }
      ul {
        list-style: none;
        padding: 0;
        li {
          color: $black !important;
          margin-bottom: 10px;
          &:hover {
            font-family: $HB !important;
          }
        }
      }
    }
  }
}

// page catégorie
.productCategorie {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .productCategorieBox {
    width: 85%;
    h2 {
      margin: 15px 0 20px 0;
    }
    .productCategorieSousCategorieList {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 10px;
      grid-auto-rows: minmax(100px, auto);
      .productCategorieSousCategorieListCard {
        div {
          width: 100%;
          background-color: #f9f7f4;
          height: 100px;
          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
        p {
          text-align: center;
        }
        margin: 0 0 20px 0;
      }
      .productCategorieSousCategorieListCard:nth-child(n + 7) {
        display: none;
      }
    }
    .productCategorieSousCategorieListShowAll {
      .productCategorieSousCategorieListCard:nth-child(n + 7) {
        display: block;
      }
    }
    .showMoreproductSousCategorie {
      margin: 0 0 20px 0;
      text-decoration: underline;
      cursor: pointer;
    }
    .productCategorieDescription {
      margin: 0 0 20px 0;
    }
  }
}
