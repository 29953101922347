.universContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .universBox {
    margin-top: 80px;
    margin-bottom: 80px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(352px, 352px);
    .universCard {
      background-color: $color-3;
      position: relative;
      border-radius: 10px;
      img {
        border-radius: 10px;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      a {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        &:hover {
          div {
            background: rgba(0, 131, 62, 0.8);
          }
        }
      }
      div {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translate(-50%, -50%);
        color: $white;
        text-align: center;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        p {
          width: 80%;
        }
        .univerCardTitle {
          font-family: $P22 !important;
          font-size: 28px;
          margin-bottom: 24px;
          font-weight: bold;
        }
      }
    }
  }
}

// UN UNIVER
.univerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .univerGrid {
    margin-top: 80px;
    margin-bottom: 80px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(352px, 352px);
    .univerGridCard {
      position: relative;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
      a {
        &:hover {
          div {
            background: rgba(0, 131, 62, 0.8);
          }
        }
      }
      div {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        transform: translate(-50%, -50%);
        color: #ffffff;
        text-align: center;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        p {
          font-family: "p22-mackinac-pro" !important;
          font-size: 28px;
          width: 80%;
          line-height: 47px;
        }
      }
    }
  }
}

// UNIVERS ACCUEIL

.showUnivers {
  background-color: $color-4;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  position: relative;
  p {
    padding-top: 20px;
    max-width: 840px;
    margin: 0 auto;
  }
  .link_univers_title {
    font-size: 16px;
    text-decoration: underline;
    text-align: center;
    display: block;
    margin-top: 16px;
    &:hover {
      color: $color-1;
    }
  }
  ul.contain-ul-univers {
    position: relative;
    padding-left: 0;
    text-align: center;
    list-style: none;
    li.liFirst {
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: 48px;
      margin-top: 48px;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      cursor: pointer;
      p {
        font-size: 24px;
        font-family: $HB;
        color: $grey;
        font-weight: 800;
        &:hover {
          color: $color-1;
        }
      }
      .ulSousUnivers {
        display: none;
        &:hover {
          .img-univers-1,
          .img-univers-2 {
            display: block;
            animation: fadeIn 0.4s forwards;
          }
        }
      }
      .ulSousUnivers.activeSousUnivers {
        padding-left: 0;
        padding-top: 16px;
        display: block;
        // overflow: hidden;
        list-style: none;
        text-align: center;
        animation: fadeIn 0.4s forwards;
        li {
          text-transform: none;
          color: $grey;
          margin-bottom: 10px;
          margin-top: 10px;
          a {
            font-size: 16px;
            font-family: $HR !important;
            text-transform: uppercase;
            width: 420px;
            display: block;
            margin: 0 auto;
            &:hover {
              text-decoration: underline;
              color: $color-1;
            }
          }
        }
      }
      .img-univers-1.active,
      .img-univers-2.active {
        display: block;
        animation: fadeIn 0.4s forwards;
      }

      @keyframes fadeIn {
        from {
          opacity: 0%;
        }
        to {
          opacity: 100%;
        }
      }
      .imgUniversShow {
        width: 324px;
        height: 348px;
        max-height: 100%;
        border-radius: 16px;
        object-fit: cover;
        position: absolute;
      }
      // IMAGE UNIVERS
      .img-univers-1 {
        display: none;
        position: absolute;
        width: 324px;
        height: 348px;
        max-height: 100%;
        border-radius: 16px;
        object-fit: cover;
        top: auto;
        bottom: auto;
        right: 0%; /* à 50%/50% du parent référent */
      }
      .img-univers-2 {
        display: none;
        position: absolute;
        width: 324px;
        height: 348px;
        max-height: 100%;
        border-radius: 16px;
        object-fit: cover;
        top: auto;
        bottom: auto;
        left: 0%; /* à 50%/50% du parent référent */
      }
      &:hover {
        .img-univers-1,
        .img-univers-2 {
          display: block;
          animation: fadeIn 0.4s forwards;
        }
      }
      &:first-child {
        .img-univers-1 {
          bottom: 50%;
        }
        .img-univers-2 {
          top: 5%;
        }
      }

      &:nth-child(2) {
        .img-univers-1 {
          bottom: 45%;
        }
        .img-univers-2 {
          top: 10%;
        }
      }

      &:nth-child(3) {
        .img-univers-1 {
          bottom: 40%;
        }
        .img-univers-2 {
          top: 20%;
        }
      }

      &:nth-child(4) {
        .img-univers-1 {
          bottom: 40%;
        }
        .img-univers-2 {
          top: 30%;
        }
      }

      &:nth-child(5) {
        .img-univers-1 {
          bottom: 40%;
        }
        .img-univers-2 {
          top: 40%;
        }
      }

      &:nth-child(6) {
        .img-univers-1 {
          bottom: 45%;
        }
        .img-univers-2 {
          top: 50%;
        }
      }

      &:nth-child(7) {
        .img-univers-1 {
          bottom: 55%;
        }
        .img-univers-2 {
          top: 60%;
        }
      }
      &:nth-child(8) {
        .img-univers-1 {
          bottom: 60%;
        }
        .img-univers-2 {
          top: 60%;
        }
      }
    }
  }
}

.imgUniversStepSwiper {
  position: absolute;
}
.swiper.imgSwiperContainer_1 {
  left: 0;
  top: 0;
  width: 340px;
  height: 360px;
}
.swiper.imgSwiperContainer_2 {
  left: 0;
  top: 50%;
  width: 262px;
  height: 274px;
  transform: translate(0%, -50%);
}
.swiper.imgSwiperContainer_3 {
  right: 0;
  top: 32px;
  width: 298px;
  height: 316px;
}
.swiper.imgSwiperContainer_4 {
  left: 0;
  bottom: 54px;
  width: 292px;
  height: 308px;
}
.swiper.imgSwiperContainer_5 {
  right: 0;
  bottom: 40%;
  width: 262px;
  height: 274px;
  transform: translate(0%, 0%);
}
.swiper.imgSwiperContainer_6 {
  right: 0;
  bottom: 0;
  width: 420px;
  height: 360px;
}

// .imgUniversShowFirst{
//   height: auto;
//   width: 100%;
//   max-width: 320px;
//   object-fit: contain;
// }
