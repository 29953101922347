@media screen and (max-width: 1900px) {
  .homeContainer
    .homePresentationBannerTopCarrousel
    .swiper-slide.swiper-slide-active {
    width: 380px !important;
  }
}

@media screen and (max-width: 1800px) {
  .homeContainer
    .homePresentationBannerTopCarrousel
    .swiper-slide.swiper-slide-active {
    width: 340px !important;
  }
}

@media screen and (max-width: 1440px) {
  .homeContainer .homePresentationBanner {
    min-height: 680px;
  }
  .homeContainer .containGridHome {
    min-height: 680px;
  }
  .homeContainer .homePresentationBannerTopCarrousel {
    min-height: 680px;
    transform: translate(0%, -30%); /* décalage de 50% de sa propre taille */
  }
  .homeContainer
    .homePresentationBannerTopCarrousel
    .swiper-slide.swiper-slide-active {
    height: 380px;
    width: 280px !important;
  }

  .homeContainer .homePresentationBannerTopCarrousel .swiper-slide {
    height: 320px;
  }

  // PRODUITS ACCUEIL
  .proposeProductsContainer {
    h2 {
      margin-left: 0;
    }
    .proposeProductsShowMore {
      margin-left: 0;
    }
  }

  .proposeRealisationContainer .proposeRealisationCarousel {
    .swiper-button-prev {
      left: 88%;
    }
  }

  // .vectorPlanShowroom {
  //   left: -180px;
  // }

  // REALISATION
  .realisationsContainer .realisationBannerContainer .square {
    width: 150px;
  }
}
@media screen and (max-width: 1199px) {
  // .vectorPlanShowroom {
  //   left: -200px;
  // }
  .realisationsContainer
    .realisationsUniversFilterBox
    .realisationsUniversFilter {
    justify-content: flex-start;
  }
  .header .universOverlay .containerUniversHeader {
    justify-content: flex-start;
  }
  // Header
  .header {
    .headerBox {
      a {
        img {
          width: 90px;
        }
      }
      .headerNav {
        margin-left: 6px;
        nav {
          a {
            font-size: 14px;
          }
        }
      }
      .headerPhone {
        margin-right: 24px;
        a {
          font-size: 16px;
        }
      }
      .expoBtn {
        margin-right: 16px;
      }
      .aideEtContactBtn {
        margin-right: 16px;
      }
      .favorisBtn {
        margin-right: 16px;
      }
    }
  }

  .homeContainer {
    .homePresentationBannerTopInfoText {
      width: 50%;
    }
    .homePresentationBannerTopCarrousel {
      width: 50%;
    }
  }

  // HOME
  .showUnivers ul.contain-ul-univers li.liFirst {
    .img-univers-1 {
      width: 264px;
      height: 278px;
    }
    .img-univers-2 {
      width: 264px;
      height: 278px;
    }
    a {
      word-break: break-word;
      width: 420px;
      display: block;
    }
    &:first-child {
      .img-univers-1 {
        bottom: 60%;
      }
      .img-univers-2 {
        top: 5%;
      }
    }

    &:nth-child(2) {
      .img-univers-1 {
        bottom: 55%;
      }
      .img-univers-2 {
        top: 10%;
      }
    }

    &:nth-child(3) {
      .img-univers-1 {
        bottom: 40%;
      }
      .img-univers-2 {
        top: 20%;
      }
    }

    &:nth-child(4) {
      .img-univers-1 {
        bottom: 40%;
      }
      .img-univers-2 {
        top: 30%;
      }
    }

    &:nth-child(5) {
      .img-univers-1 {
        bottom: 40%;
      }
      .img-univers-2 {
        top: 40%;
      }
    }

    &:nth-child(6) {
      .img-univers-1 {
        bottom: 40%;
      }
      .img-univers-2 {
        top: 50%;
      }
    }

    &:nth-child(7) {
      .img-univers-1 {
        bottom: 35%;
      }
      .img-univers-2 {
        top: 60%;
      }
    }
    &:nth-child(8) {
      .img-univers-1 {
        bottom: 30%;
      }
      .img-univers-2 {
        top: 60%;
      }
    }
  }
  .container_card_inspiration {
    img {
      height: 280px;
    }
  }

  // SUBFOOTER
  .subFooter {
    .subFooterRightCitation {
      .subFooterIndex {
        p {
          font-size: 28px;
          line-height: 38px;
        }
      }
    }
  }

  .realisationsContainer .realisationBannerContainer .square {
    width: 130px;
    height: 150px;
  }

  .containItemImageRealisation {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    img {
      object-fit: contain;
    }
  }
  .containItemTextRealisation {
    padding-top: 96px;
    .itemTextRealisation {
      max-width: 100%;
    }
  }
  .homeContainer
    .homePresentationBannerTopCarrousel
    .swiper-slide.swiper-slide-active {
    height: 380px;
    width: 240px !important;
  }
}
@media screen and (max-width: 1176px) {
  .entrepriseContainer {
    .entrepriseBox {
      .entrepriseTimeline {
        .line .step-2 img {
          right: 80px;
        }
      }
    }
  }
  .realisationsContainer .realisationBannerContainer .square {
    // width: 100px;
    height: 150px;
  }
  .searchBox .resultOverlay .resultBox .resultList {
    grid-template-columns: repeat(2, 1fr);
    .resultCardTitle {
      padding-top: 24px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .homeContainer
    .homePresentationBannerTopCarrousel
    .swiper-slide.swiper-slide-active {
    height: 380px;
    width: 240px !important;
  }
  .proposeRealisationContainer .proposeRealisationCarousel {
    .swiper-button-prev {
      left: 86%;
    }
  }
  .vectorPlanShowroom {
    left: 30%;
    transform: translate(-50%, 0);
  }
  // HEADER
  .burgerBackdrop .burger {
    width: 80%;
  }
  .burgerBackdrop .burgerContent .burgerLink.burgerLinkHideDesktop {
    display: block;
  }
  .burgerBackdrop .burgerContent .burgerLink a {
    font-size: 24px;
  }
  .header {
    .headerBox {
      .aideEtContactBtn {
        margin-right: 0;
        p {
          display: none;
        }
        .nav-subheader {
          p {
            display: block;
          }
        }
      }
      .headerNav {
        width: 100%;
        margin-left: 16px;
        nav {
          display: none;
        }
      }
      .expoBtn {
        margin-right: 8px;
        a {
          p {
            display: none;
          }
        }
      }
      .favorisBtn {
        margin-top: -10px;
        margin-right: 8px;
        a {
          p {
            display: none;
          }
        }
      }
    }
  }
  .arrowScroll {
    display: none;
  }
  .homeContainer {
    .homePresentationBannerTopInfoText {
      width: 50%;
      .homePresentationBannerSubInfo {
        display: block;
        .arrowButton {
          margin-bottom: 32px;
        }
        .whoAreWeBtn {
          margin-left: 0;
        }
      }
    }
    .homePresentationBannerTopCarrousel {
      width: 50%;
    }
  }
  .showUnivers ul.contain-ul-univers li.liFirst {
    .img-univers-1,
    .img-univers-2 {
      width: 230px;
    }
  }
  // PRODUITS ACCUEIL
  .proposeProductsContainer {
    h2 {
      font-size: 34px;
      line-height: 44px;
      span {
        font-size: 34px;
        line-height: 44px;
      }
    }
    .proposeProductsShowMore {
      margin-left: 0;
    }
  }
  // PRODUITS
  .productStep .swiper.imgSwiperContainer_5 {
    left: 10%;
  }
  .productsExplorerContainer {
    .searchProductExplorer {
      .universFilterListOpen,
      .categoryFilterListOpen {
        height: 100%;
      }
      .categoryFilterListCard {
        padding-bottom: 16px;
      }
    }
  }

  .container_card_inspiration {
    img {
      height: 230px;
    }
  }

  .missionContainer
    .missionBox
    .missionBottom
    .missionBottomRight
    .missionBottomRightBox
    .missionBottomRightStep {
    height: auto;
    margin-bottom: 24px;
  }

  .entrepriseContainer {
    .entrepriseBox {
      .entrepriseTimeline {
        .line .step-2 img {
          right: 40px;
          top: -70px;
        }
        .line .step-2 {
          top: 80px;
        }
        .line .dot-3 {
          left: 70%;
        }
        .line .step-3 {
          left: 85%;
        }
      }
      .entrepriseInfos {
        .entrepriseNumber {
          grid-template-columns: repeat(2, 1fr);
          display: grid;
          height: 100%;
          padding: 24px 0;
          div {
            width: 100%;
          }
          &:nth-child(1),
          :nth-child(2) {
            div {
              margin-bottom: 24px;
            }
          }
        }
      }
    }
  }
  .bannerProduct {
    .floating_1 {
      top: 40%;
      left: -10%;
    }
    .floating_3 {
      right: 5%;
      top: -8%;
    }
    .floating_4 {
      bottom: -10%;
    }
    .floating_5 {
      bottom: -35%;
    }
    .floating_6 {
      right: -15%;
    }
  }

  .realisationBannerContainer {
    p {
      max-width: 370px;
      margin: 0 auto;
    }
  }

  .modalService {
    .contentModal {
      display: flex;
    }
    .contentModalImg {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 10px;
      img {
        width: 100%;
        height: auto;
        max-height: 320px;
      }
    }
  }

  .SRContainer {
    .SRIntroBox {
      // flex-direction: column;
      .SRIntro {
        width: 45%;
        h1 {
          font-size: 42px;
        }
        h2 {
          font-size: 32px;
          line-height: 42px;
        }
        // width: 85% !important;
      }
      .SRIntroImg {
        div {
          &:nth-child(1) {
            left: -50px;
          }
          &:nth-child(2) {
            left: -50px;
            bottom: -40px;
          }
          &:nth-child(4) {
            right: -100px;
            bottom: -60px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 981px) {
  h1 {
    span {
      font-size: 44px;
    }
    font-size: 44px;
  }
  .proposeRealisationContainer {
    padding-left: 24px;
    .proposeRealisationCarousel {
      .swiper-button-prev {
        left: 86%;
      }
    }
  }
  .vectorPlanShowroom {
    left: 25%;
    transform: translate(-50%, 0);
    .MuiTypography-root.link1,
    .MuiTypography-root.link2,
    .MuiTypography-root.link3,
    .MuiTypography-root.link4 {
      font-size: 20px;
    }
    .showroomLink1 {
      left: 37%;
    }
    .showroomLink4 {
      right: 170px;
    }
  }
  // HEADER
  .header {
    .headerBox {
      a {
        img {
          width: 78px;
        }
      }
    }
  }
  .missionContainer
    .missionBox
    .missionBottom
    .missionBottomRight
    .missionBottomRightBox {
    width: 100%;
    margin-top: 64px;
  }
  // UN PRODUIT
  .productViewContainer .productViewMoreInfos {
    padding-top: 0;
  }
  .productViewContainer .productViewDevisDemande a {
    margin-top: 8px;
  }
  .productStep .swiper.imgSwiperContainer_5 {
    left: 10%;
  }
  // HOME
  .showUnivers ul.contain-ul-univers li.liFirst {
    .img-univers-1 {
      position: relative;
      bottom: auto;
      width: 100%;
      margin-top: 24px;
    }
    .img-univers-2.active {
      display: none;
    }
  }
  .entrepriseContainer {
    .entrepriseBox {
      .entrepriseCitation {
        height: 100%;
      }
    }
  }

  .realisationsContainer .realisationBannerContainer .square {
    width: 180px;
    height: auto;
    .imgRea2,
    .imgRea4,
    .imgRea6,
    .imgRea8 {
      display: none;
    }
  }
  .universContainer .universBox {
    grid-template-columns: repeat(2, 1fr);
  }
  .univerContainer .univerGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  .entrepriseContainer {
    .entrepriseBox {
      .entrepriseCitation {
        height: 100%;
        &.pt120 {
          padding-top: 0;
        }
        &.mb90 {
          margin-bottom: 0;
        }
        #citation {
          margin-top: 64px;
          max-width: 100%;
        }
      }
    }
  }
  .bannerProduct {
    .floating_1 {
      top: 40%;
      left: -15%;
    }
    .floating_2 {
      top: -8%;
    }
    .floating_3 {
      right: -5%;
      top: -8%;
    }
    .floating_4 {
      bottom: -10%;
    }
    .floating_5 {
      bottom: -35%;
    }
    .floating_6 {
      right: -25%;
    }
  }
}

@media screen and (max-width: 899px) {
  .homeContainer
    .homePresentationBannerTopCarrousel
    .swiper-slide.swiper-slide-active {
    height: 380px;
    width: 340px !important;
  }
  // HEADER
  .header {
    .headerBox {
      .headerPhone {
        display: none;
      }
    }
  }
  // HOME HERO
  .homeContainer {
    .homePresentationBanner {
      padding-top: 90px;
      height: 100%;
    }
    .containGridHome {
      display: block;
    }
    .homePresentationBannerTopInfoText {
      width: 100%;
      max-width: 100%;
      padding-left: 8%;
      padding-bottom: 64px;
    }
    .homePresentationBannerTopCarrousel {
      position: relative;
      // height: 372px;
      width: 100%;
      min-height: auto;
      max-width: 100%;
      transform: translate(0%, -20%); /* décalage de 50% de sa propre taille */
      // img {
      //   margin-left: 0;
      //   width: 100%;
      // }
    }
  }
  // Inspiration
  .contain-grid-inspiration {
    .item-grid-inspiration {
      &:nth-child(2) {
        margin-top: 0;
      }
      &:nth-child(4),
      &:nth-child(6) {
        margin-top: 0;
      }
      &:nth-child(7),
      &:nth-child(9) {
        margin-top: 0;
      }
    }
  }

  // HOME INSPIRATION
  .showInspirations
    .contain-grid-show-inspiration
    .MuiGrid-root:nth-child(even) {
    margin-top: 0;
  }
  .showInspirations .item-grid-show-inspiration {
    margin-top: 0;
  }

  // PRODUITS ACCUEIL
  .proposeProductsContainer {
    text-align: center;
    h2 {
      padding-top: 0;
      margin-bottom: 24px;
    }
  }

  // SUBFOOTER
  .subFooter {
    .subFooterRightCitation {
      height: 292px;
    }
    .subFooterLeft {
      height: 292px;
    }
    .subFooterRight {
      height: 292px;
    }
  }

  // PRODUITS

  .productStep .swiper.imgSwiperContainer_5 {
    left: 10%;
  }
  .productsExplorerContainer {
    .productsCategories {
      padding-top: 32px;
    }
    .productsVisualizerContainer {
      padding: 32px 15px 0 15px;

      .productsGrid {
        .productCard {
          height: 240px;
          max-height: 100%;
          // padding: 20px 0;
          a {
            height: 240px;
            max-height: 100%;
            display: inline-flex;
            align-items: center;
            position: relative;
            &::after {
              content: "";
              display: block;
              margin-top: 16px;
              border-bottom: 1px solid #f4f4f4;
              position: absolute;
              bottom: 0;
              width: 100%;
            }
            img {
              width: 180px;
            }
            .productCardAddFav {
              top: 0;
            }
          }
          picture {
            width: 180px !important;
            object-fit: contain;
            margin-right: 16px;
          }
          .titleProductCard {
            margin-top: 0;
          }
        }
      }
    }
    .searchProductExplorer {
      .universFilterListOpen,
      .categoryFilterListOpen {
        overflow-x: scroll;
        max-width: 100vw;
        position: relative;
        white-space: nowrap;
        &::-webkit-scrollbar {
          height: 2px;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
        }
        &::-webkit-scrollbar-thumb {
          height: 2px;
          background-color: $color-1;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: $light-grey;
        }
        &::-webkit-scrollbar:vertical {
          display: none;
        }
      }
      .universFilterListCard {
        margin: 0 6px;
      }
    }
  }

  .distributeurContainer {
    min-height: 991px;
  }
  .distributeurBox,
  .distributeurForm {
    height: 100%;
  }
  .distributeurContainer .distributeurBox .distributeurForm .loginBox,
  .distributeurContainer
    .distributeurBox
    .distributeurForm
    .MuiGrid-grid-lg-8::before {
    height: 100%;
    min-height: auto;
  }
  .distributeurContainer
    .distributeurBox
    .distributeurForm
    .MuiGrid-grid-lg-8::after {
    height: 100%;
    // position: relative;
    width: 100%;
  }
  .distributeurContainer .distributeurBox .h100 {
    min-height: auto;
  }
  .gridContainerConnexionInformation {
    padding: 96px 0;
  }
  .distributeurContainer .distributeurBox .distributeurForm .MuiGrid-grid-lg-4 {
    padding: 24px;
  }

  //UNIVERS PAGE
  .sousUniverBanner {
    height: 100%;
  }
  .sousUniverBanner .sousUniverBannerLeft {
    padding: 96px 0 64px;
    height: 100%;
  }
  .sousUniverBanner .sousUniverBannerRight {
    height: auto;
    width: 100%;
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .entrepriseContainer {
    .entrepriseBox {
      .entrepriseTimeline {
        .line .step-2 img {
          right: 40px;
          top: -70px;
        }
        .line .step-2 {
          top: 80px;
        }
        .line .dot-3 {
          left: 66%;
        }
        .line .step-3 {
          // left: 80%;
          width: 40%;
        }
      }
      .entrepriseCitation {
        height: 100%;
      }
    }
  }
  .lastActuImage {
    position: relative;
    width: 100%;
    padding-top: 24px;
    picture {
      width: 100%;
      display: block;
    }
    img {
      position: relative;
      width: 100%;
      height: 420px;
      max-height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
  }
  .actusContainer.pt120,
  .allActus.pt120 {
    padding-top: 96px;
  }
  .realisationContainer {
    display: block;
  }
  .realisationContainer .realisationBannerImage .swiper {
    position: relative;
    width: 100%;
  }

  .containerItemImageTextRealisation {
    padding-top: 60px;
  }

  .realisationsContainer .firstRealisationBox .firstRealisationLeft {
    display: block;
  }
  .realisationsContainer .realisationBannerContainer .square .imgRealisation {
    display: none;
  }
  .realisationsContainer
    .firstRealisationBox
    .firstRealisationLeft
    .firstRealisationLeftDiv {
    width: 100%;
  }
  .realisationsContainer .firstRealisationBox .firstRealisationRight {
    width: 100%;
    max-height: 320px;
    img {
      margin-top: 10px;
      border-radius: 20px;
    }
  }
  // SERVICES
  .bannerService {
    height: 1000px;
  }

  .imgSchemas {
    width: 100%;
    object-fit: contain;
    &.hideMobile {
      display: none;
    }
    &.hideDesktop {
      display: block;
    }
  }
  // UN PRODUIT
  .productViewContainer .productViewInfos {
    padding-top: 24px;
    .dFlexProductFav {
      h2 {
        max-width: 420px;
      }
    }
  }
  .SRContainer {
    .SRIntroBox {
      // flex-direction: column;
      .SRIntro {
        width: 45%;
        h1 {
          font-size: 42px;
        }
        h2 {
          font-size: 32px;
          line-height: 42px;
        }
        // width: 85% !important;
      }
      .SRIntroImg {
        div {
          &:nth-child(1) {
            left: -50px;
          }
          &:nth-child(2) {
            left: -50px;
            bottom: -40px;
          }
          &:nth-child(4) {
            right: -100px;
            bottom: -60px;
          }
        }
      }
    }
    .SRConception {
      height: 100%;
      .SRConceptionLeft {
        img {
          object-fit: contain;
          width: 100%;
        }
      }
      .SRConceptionRight {
        .SRConceptionRightPres {
          h2 {
            font-size: 32px;
            line-height: 42px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .vectorPlanShowroom {
    position: relative;
    left: auto;
    transform: translate(0);
    .showroomLink {
      position: relative;
      left: auto;
      right: auto;
      top: auto;
    }
    .divImgShowroom {
      height: 100px;
      position: relative;
    }
    img {
      position: absolute;
      transform: translate(-50%, 0);
      // width: 900px;
      // margin-left: -30%;
    }
    .MuiTypography-root.link1,
    .MuiTypography-root.link2,
    .MuiTypography-root.link3,
    .MuiTypography-root.link4 {
      padding-right: 0;
      padding-left: 0;
      span {
        color: $black;
        display: block;
        text-transform: uppercase;
        font-size: 10px;
        padding: 0;
      }
      &::before {
        content: "";
        display: none;
      }
      &::after {
        content: "";
        display: none;
      }
    }
  }
  .bannerRecherche {
    h1 {
      font-size: 38px;
    }
  }
  .searchBox .resultOverlay .resultBox {
    margin-top: 48px;
  }
  .searchBox .resultOverlay .resultBox .resultList {
    display: block;
    .resultListTypeproducts {
      display: flex;
      border-right: none;
    }
    .resultListReaInspi {
      margin-top: 24px;
      display: flex;
      border-left: none;
    }
  }
  #header {
    height: 180px;
    .header-inner {
      height: 100%;
    }
    .headerNav {
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 96%;
    }
  }
  .cookieOverlay {
    display: block;
    p {
      width: 100%;
      margin-bottom: 24px;
      padding: 0 24px;
    }
    div {
      justify-content: end;
      padding-right: 24px;
    }
  }
  .burgerBackdrop .burger {
    width: 100%;
  }
  .header .headerBox {
    .logoEtSearchBar {
      justify-content: flex-start;
    }
  }
  .pt90 {
    padding-top: 64px;
  }
  .homeContainer {
    .containGridHome {
      display: block;
    }
    .homePresentationBannerTopInfoText {
      width: 100%;
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
    .homePresentationBannerTopCarrousel {
      width: 100%;
      max-width: 100%;
      img {
        margin-left: 0;
        width: 100%;
      }
    }
  }
  // BANNERS
  .banner {
    max-height: 100%;
  }
  .inspirationBanner {
    .inspirationBannerPrimary {
      display: block;
      height: auto;
    }
    .inspirationBannerLeftContainer {
      padding-top: 64px;
      padding-bottom: 32px;
    }
    .inspirationBannerLeft {
      // height: 420;
      height: 100%;
    }
    .inspirationBannerRight {
      height: 320px;
    }
  }
  .inspirationInfoPratiqueBox {
    padding: 0;
    .inspirationInfoPratique {
      width: 100%;
    }
  }

  // PRODUITS
  .productsExplorerContainer {
    padding: 64px 32px !important;
    .searchProductExplorer {
      section {
        display: block;
        .containerBtnUniversCategories {
          padding-top: 16px;
          .btnUniversFiltersSearch {
            position: relative;
            flex-direction: row;
            align-items: center;
            div {
              margin-top: 0;
              margin-left: 8px;
            }
            p {
              display: none;
            }
          }
        }
      }
    }
    .productsVisualizerContainer {
      .productsGrid {
        .productCard {
          .pictureProductCard {
            width: 140px;
          }
          a {
            min-width: 100%;
          }
        }
      }
    }
  }
  .productsCategories {
    .productsCategoriesBox {
      .productsCategoriesCard {
        padding-bottom: 16px;
        .productsCategoriesCardImage {
          height: 120px;
        }
      }
    }
  }

  // CONTACT
  .contactContainer,
  .devisContainer {
    .contactForm,
    .devisForm {
      display: block;
      .contactField,
      .devisField {
        margin-bottom: 24px;
      }
    }
  }

  // BANDEAU LOCALISATION
  .bandeauLocalisation {
    display: block;
    height: 100%;
    .bandeauLocalisationLeft,
    .bandeauLocalisationRight {
      height: 280px;
      width: 100%;
    }
    .bandeauLocalisationLeft {
      div:last-child {
        margin-bottom: 0;
      }
    }
  }
  .missionContainer {
    .missionBox {
      .missionTop {
        .friseMissions {
          margin-top: 0;
          .friseMissionsCards {
            display: grid;
            justify-content: space-evenly;
            grid-template-columns: repeat(2, 1fr);
            .friseMissionsCard {
              width: auto;
              margin-bottom: 64px;
            }
          }
          .friseMissionsLine {
            display: none;
          }
        }
      }
      .missionBottom {
        margin-top: 0;
      }
    }
  }

  // INSPIRATION
  .inspirationApprocheBudget {
    display: block;
    height: auto;
    .inspirationApprocheBudgetLeft {
      width: 100%;
    }
    .inspirationApprocheBudgetRight {
      width: 100%;
      padding-left: 15%;
      h3 {
        padding-top: 24px;
      }
    }
  }

  .entrepriseContainer {
    .entrepriseBox {
      .entrepriseTimeline {
        .line {
          .dot-1 {
            left: 0;
          }
          .step-1 {
            width: 40%;
            left: 18%;
            top: -80px;
          }
          .dot-2 {
            left: 30%;
          }
          .step-2 {
            width: 50%;
            top: 130px;
            left: 48%;
            img {
              position: relative;
              top: auto;
              right: auto;
              margin-top: 16px;
            }
          }
          .step-3 {
            width: 50%;
            top: -80px;
          }
          .dot-3 {
            left: 60%;
          }
        }
      }
      .entrepriseMap {
        h3 {
          font-size: 24px;
          max-width: 300px;
        }
        &.pb120 {
          padding-bottom: 96px;
        }
      }
    }
  }
  // REALISATION
  .containItemImageRealisation {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    img {
      object-fit: contain;
    }
  }

  .realisationsContainer .realisationBannerContainer .square {
    width: 150px;
    height: auto;
  }
  .modalService {
    .contentModal {
      flex-direction: column-reverse;
      .MuiTypography-root {
        margin-bottom: 24px;
      }
    }
    .contentModalText {
      width: 100%;
      padding-left: 0;
    }
    .contentModalImg {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      img {
        width: 100%;
        height: auto;
        max-height: 320px;
      }
    }
  }
  .distributeurContainerSigned .distributeurBox .cataloguesTypeBox,
  .distributeurContainerSigned .distributeurBox .NoticesTypeBoxTuto {
    padding-left: 0 !important;
  }
  .distributeurContainerSigned .distributeurBox .cataloguesTypeBox h3,
  .distributeurContainerSigned .distributeurBox .NoticesTypeBoxTuto h3 {
    position: relative !important;
    transform: rotate(0) !important;
    left: auto !important;
    top: auto !important;
    padding-bottom: 16px;
  }
  // CATALOGUE / NOTICE ...
  .cataloguesContainer .cataloguesTypeBox,
  .NoticesTutosContainer .NoticesTypeBox {
    display: block;
    padding: 24px 0;
    h3 {
      position: relative;
      transform: rotate(0);
      left: auto;
      top: auto;
      padding-bottom: 16px;
    }
    .cataloguesGrid div,
    .NoticesGrid div {
      justify-content: flex-start;
      align-items: flex-start;
      p {
        text-align: left;
      }
    }
  }

  .searchBox .resultOverlay .resultBox .resultList {
    grid-template-columns: repeat(1, 1fr);
  }
  .bannerProduct {
    .floating_1 {
      top: 40%;
      left: -25%;
    }
    .floating_2 {
      top: -8%;
    }
    .floating_3 {
      right: -20%;
      top: -8%;
    }
    .floating_4 {
      bottom: -20%;
      left: 40%;
    }
    .floating_5 {
      bottom: -45%;
      right: -20%;
    }
    .floating_6 {
      right: -45%;
    }
  }

  // PRODUITS
  .productStep {
    height: 440px;
  }
  .productStepBox {
    .swiper {
      display: none;
    }
  }

  // SR L'evolutif
  .SRIntroBox {
    flex-direction: column;
    .SRIntro {
      width: 85% !important;
    }
    .SRIntroImg {
      // display: none;
    }
  }
  .SRConception {
    flex-direction: column;
    height: auto !important;
    .SRConceptionLeft {
      width: 85% !important;
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    }
    .SRConceptionRight {
      padding-top: 24px;
      width: 100% !important;
      .SRConceptionRightNotice {
        flex-direction: column;
        div {
          width: 100% !important;
          &:nth-child(1) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 681px) {
  .searchBox {
    display: block;
    .searchBoxIcon {
      min-height: 40px;
      display: block;
      background-color: transparent;
    }
    input {
      width: 100%;
    }
  }
  .containItemImageRealisation {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    img {
      width: 100%;
    }
  }
  .gridVectoPlanShowroom {
    height: 500px !important;
  }
  .vectorPlanShowroom {
    .divImgShowroom {
      height: 180px;
      position: relative;
    }
    .showroomLink {
      height: auto;
    }
    .containShowroomLink {
      display: block;
      height: auto;
      .MuiTypography-root {
        padding-bottom: 16px;
        display: block;
      }
    }
    img {
      padding-top: 24px;
      padding-bottom: 24px;
      transform: translate(-60%, 0);
    }
  }
  .homeContainer .homePresentationBannerTopCarrousel .swiper-slide {
    height: 280px;
  }
  .homeContainer
    .homePresentationBannerTopCarrousel
    .swiper-slide.swiper-slide-active {
    height: 340px;
  }
}

@media screen and (max-width: 599px) {
  .cookieOverlay .cookieModal {
    display: block;
  }
  .cookieOverlay .cookieModal p {
    width: 100%;
  }
  .cookieOverlay .cookieModal div {
    justify-content: center;
  }
  .searchBox .resultOverlay .resultBox .resultList {
    display: block;
    padding: 24px 0;
    .resultListTypeproducts {
      display: block;
      margin-bottom: 24px;
    }
    .resultListReaInspi {
      display: block;
    }
  }
  .pb120 {
    padding-bottom: 64px;
  }
  h1 {
    span {
      font-size: 38px;
    }
    font-size: 38px;
    line-height: 48px;
  }
  h2 {
    font-size: 32px;
    line-height: 42px;
  }
  h3 {
    font-size: 24px;
    line-height: 34px;
  }

  .homeContainer {
    .homePresentationBannerTopInfoText {
      h1 {
        span {
          font-size: 38px;
        }
      }
      .homePresentationBannerSubInfo {
        display: block;
        .arrowButton {
          margin-bottom: 32px;
        }
        .whoAreWeBtn {
          margin-left: 0;
        }
      }
    }
  }

  // INSPIRATION ACCUEIL
  .showInspirations {
    .contain-grid-show-inspiration {
      .MuiGrid-root {
        &:nth-child(even) {
          margin-top: 0;
        }
      }
    }
  }

  // PRODUITS
  .productsExplorerContainer {
    .productsVisualizerContainer {
      .productsGrid {
        .productCard {
          padding: 20px 0;
          a {
            display: inline-flex;
            align-items: center;
          }
          .pictureProductCard {
            height: 118px;
            width: 94px;
            margin-right: 8px;
          }
        }
      }
    }
  }

  .missionContainer {
    .missionBox {
      .missionTop {
        .friseMissions {
          width: 100%;
        }
      }
    }
  }

  .banner-inspiration {
    height: 420px;
    &::before {
      height: 420px;
    }
  }
  .subFooterIndex {
    text-align: center;
  }

  // INSPIRATION
  .inspirationApprocheBudget {
    display: block;
    height: auto;
    .inspirationApprocheBudgetLeft {
      width: 100%;
    }
    .inspirationApprocheBudgetRight {
      width: 100%;
      padding-left: 5%;
      h3 {
        padding-top: 24px;
      }
    }
  }

  // DEVIS
  .devisCheckBoxMosaique {
    grid-template-columns: repeat(1, 1fr) !important;
    width: 100% !important;
    div {
      margin-bottom: 20px;
    }
  }

  .universContainer .universBox {
    grid-template-columns: repeat(1, 1fr);
  }
  .univerContainer .univerGrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .entrepriseContainer {
    .entrepriseBox {
      .entrepriseInfos {
        .entrepriseNumber {
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 32px;
          padding-bottom: 0;
          grid-template-columns: repeat(1, 1fr);
          &:nth-child(1),
          :nth-child(2) {
            div {
              margin-bottom: 34px;
            }
          }
        }
      }
      .entrepriseMap {
        h3 {
          padding: 64px 0;
          max-width: 100%;
        }
        &.pb120 {
          padding-bottom: 0;
        }
        img {
          width: 100%;
        }
      }
      .entrepriseTimeline {
        height: 100%;
        display: block;
        padding: 64px 0;
        position: relative;
        .line {
          &::before {
            height: 100%;
            width: 2px;
            background: rgb(230, 230, 230);
            content: "";
            display: block;
            position: absolute;
          }
          background-color: transparent;
          height: 100%;
          .dot-1 {
            position: relative;
            top: 6%;
            left: auto;
          }
          .step-1 {
            margin-left: 24px;
            position: relative;
            width: 100%;
            left: auto;
            top: auto;
            transform: none;
            margin-bottom: 24px;
          }
          .dot-2 {
            position: relative;
            top: 6%;
            left: auto;
          }
          .step-2 {
            margin-left: 24px;
            position: relative;
            width: 100%;
            left: auto;
            top: auto;
            transform: none;
            margin-bottom: 24px;
          }
          .dot-3 {
            position: relative;
            top: 6%;
            left: auto;
          }
          .step-3 {
            margin-left: 24px;
            position: relative;
            width: 100%;
            left: auto;
            top: auto;
            transform: none;
            margin-bottom: 24px;
          }
        }
      }
      .entrepriseCitation {
        img {
          width: 100%;
        }
      }
    }
  }
  .modalService {
    .contentModal {
      flex-direction: column-reverse;
      .MuiTypography-root {
        margin-bottom: 24px;
      }
    }
    .contentModalText {
      width: 100%;
      padding-left: 0;
    }
    .contentModalImg {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 10px;
      img {
        width: 100%;
        height: auto;
        max-height: 320px;
      }
    }
  }
  .bannerService {
    padding: 64px 0;
    .imgBanner {
      margin-top: 24px;
      position: relative;
      width: 100%;
      top: auto;
      max-width: 100%;
      max-height: 320px;
    }
    .imgBanner2 {
      display: none;
    }
  }
  .productsExplorerContainer {
    padding: 64px 32px !important;
  }
  // UN PRODUIT
  .productViewContainer .productViewInfos {
    .dFlexProductFav {
      h2 {
        max-width: 320px;
      }
    }
  }
  .productViewContainer .productViewPrimaryPictures {
    height: auto;
  }
  .productViewContainer .swiper .productViewSliderPictures div img {
    object-fit: contain;
  }
  // ARIANNE
  .ariane {
    height: auto;
  }
  .bannerProduct {
    .containerImgFloating {
      display: none;
    }
    &.banner {
      height: 280px;
    }
  }
  .filterShowroom {
    display: block;
    height: auto;
  }
  .proposeRealisationContainer .proposeRealisationCarousel .swiper-button-prev {
    left: 78%;
  }
}

@media screen and (max-width: 425px) {
  .proposeRealisationContainer .proposeRealisationCarousel .swiper-button-prev {
    left: 70%;
  }
  .filterShowroom {
    display: block;
    height: auto;
    .containerBtnShowroom {
      display: block;
    }
    .showroomCollapse {
      position: relative;
      top: 0;
      left: 0;
    }
  }
  .subFooter .subFooterLeft h2 {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 0;
  }
  .header .headerBox .headerNav {
    margin-left: 8px;
  }
  .css-1fwplzw-MuiGrid-root > .MuiGrid-item {
    padding-top: 0 !important;
  }
  .searchBox {
    .searchBoxIcon {
      min-width: 100%;
    }
    input {
      width: 100%;
      // max-width: 120px;
    }
  }

  .burgerBackdrop .burger {
    width: 100%;
  }
  .MuiButton-root.arrowButton {
    height: auto;
    line-height: normal;
    min-height: 40px;
  }

  .homeContainer {
    .homePresentationBannerTopCarrousel {
      img {
        height: 372px;
        width: 102vw;
        min-height: auto;
        // margin-left: -12%;
      }
    }
  }
  // PRODUITS
  .productStepBox {
    .swiper {
      display: none;
    }
  }
  .productsExplorerContainer {
    padding: 64px 16px !important;
    .productsVisualizerContainer {
      .productsGrid {
        .productCard {
          height: 100%;
          padding: 20px;
          a {
            display: block;
            height: 100%;
          }
          .pictureProductCard {
            height: 118px;
            width: 100%;
            margin-right: 0;
            object-fit: contain;
          }
        }
      }
    }
  }

  .missionContainer {
    .missionBox {
      .missionTop {
        .friseMissions {
          width: 100%;
          .friseMissionsCards {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
    }
  }

  .contain_info_header {
    p {
      font-size: 10px;
    }
  }
  #header .header-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
  .homeContainer
    .homePresentationBannerTopCarrousel
    .swiper-slide.swiper-slide-active {
    background-color: transparent;
    height: 380px;
    width: 100% !important;
  }
}

@media screen and (max-width: 400px) {
  .header .headerBox .headerNav {
    margin-left: 8px;
  }
}
