.burgerBackdrop {
  user-select: none;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.686);
  z-index: 1500;
  opacity: 0%;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .burger {
    width: 50%;
    background-color: $darkGrey;
    height: 100%;
    right: -50%;
    position: absolute;
    transition: right 0.3s;
  }
  .openUp {
    right: 0%;
    overflow-y: auto;
    padding-bottom: 40px;
  }
  .closeUp {
    right: -50%;
  }
  .headerBurger {
    text-align: right;
    padding: 30px 43px 48px 48px;
    svg {
      color: $white;
      cursor: pointer;
    }
    button {
      cursor: pointer;
    }
    p {
      color: $white;
      cursor: pointer;
    }
    button:hover {
      svg {
        color: $color-1;
      }
    }
  }
  .burgerContent {
    .burgerLink {
      a {
        color: $white;
        font-size: 32px;
        padding: 0 44px;
        display: block;
        position: relative;
        &:hover {
          color: $color-1;
        }
        &::after {
          content: "";
          display: block;
          position: relative;
          margin-top: 28px;
          margin-bottom: 28px;
          border-bottom: 1px solid #174432;
        }
      }
      &:last-child {
        a {
          &::after {
            display: none;
          }
        }
      }
      &.burgerLinkHideDesktop {
        display: none;
      }
    }
  }
}
.openBackdrop {
  opacity: 100%;
  pointer-events: all;
}
