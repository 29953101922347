.avisClient {
  width: 100%;
  height: 240px;
  display: flex;
  user-select: none;
  .avisClientTitle {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .avisClientSlider {
    width: 80%;
    display: flex;
    overflow: hidden;
    .avisClientCard {
      min-width: 30.33%;
      max-width: 30.33%;
      border: 1px solid #f4f4f4;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-right: 3%;
      .avisClientNote {
        height: 20%;
        svg{
          height: 50px;
        }
      }
      .avisClientCommentary {
        height: 70%;
        p {
          text-align: center;
          font-style: italic;
        }
      }
    }
  }
}
