.missionContainer {
  width: 100%;
  .missionBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    .pretitre {
      margin-bottom: 24px;
    }
    .missionTop {
      .friseMissions {
        margin-top: 48px;
        height: 100%;
        position: relative;
        .friseMissionsLine {
          height: 2px;
          background-color: rgba(0, 0, 0, 0.073);
          width: 100%;
          position: absolute;
          top: 53%;
        }
        .friseMissionsCards {
          height: 100%;
          display: flex;
          justify-content: space-evenly;
          .friseMissionsCard {
            height: 100%;
            width: 200px;
            z-index: 2;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            img {
              height: 36px;
              width: 36px;
              object-fit: contain;
              margin-bottom: 5px;
            }
            .friseMissionsCardRing {
              height: 30px;
              width: 30px;
              border: 8px solid $grey;
              border-radius: 50%;
              margin: 16px 0;
            }
            p {
              text-align: center;
              width: 100%;
            }
          }
        }
      }
    }
    .missionBottom {
      margin-top: 90px;
      display: flex;
      .missionBottomLeft {
        .missionBottomFakeGridTop {
          display: flex;
          div {
            padding: 10px;
            img {
              box-shadow: 0px 5px 10px rgb(140 140 140 / 28%);
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 7px;
            }
          }
          div:nth-child(1) {
            width: 40%;
            height: 250px;
          }
          div:nth-child(2) {
            width: 60%;
            height: 250px;
          }
        }
        .missionBottomFakeGridBottom {
          display: flex;
          div {
            padding: 10px;
            img {
              box-shadow: 0px 5px 10px rgb(140 140 140 / 28%);
              width: 100%;
              height: 100%;
              border-radius: 7px;
              object-fit: cover;
            }
          }
          div:nth-child(1) {
            width: 60%;
            height: 250px;
          }
          div:nth-child(2) {
            height: 250px;
            width: 40%;
          }
        }
      }
      .missionBottomRight {
        display: flex;
        justify-content: center;
        .missionBottomRightBox {
          width: 80%;
          .missionBottomRightStep {
            width: 100%;
            height: 166px;
            display: flex;
            justify-content: flex-start;
            .missionStepLabel {
              width: 40px;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              .missionStepLabelNumber {
                height: 40px;
                width: 40px;
                background-color: $white;
                box-shadow: 0px 5px 10px rgb(140 140 140 / 28%);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $color-1;
                font-family: $HB !important;
              }
              .missionStepLabelDashed {
                width: 1px;
                border: 1px solid $color-1;
                height: 70%;
              }
            }
            .missionStepContent {
              width: 100%;
              height: 100%;
              padding-left: 24px;

              p {
                &:nth-child(1) {
                  font-size: 24px;
                  font-family: $HB !important;
                }
                &:nth-child(2) {
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
    .missionFooter {
      margin-top: 64px;
      margin-bottom: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// KEYFRAMES

// @keyframes stepByStep {
//   0% {
//     .friseMissionsCard:nth-child(1) {
//       .friseMissionsCardRing {
//         border: 8px solid $color-1;
//       }
//     }
//   }
//   25% {
//     .friseMissionsCard:nth-child(2) {
//       .friseMissionsCardRing {
//         border: 8px solid $color-1;
//       }
//     }
//   }
//   50% {
//     .friseMissionsCard:nth-child(3) {
//       .friseMissionsCardRing {
//         border: 8px solid $color-1;
//       }
//     }
//   }
//   75% {
//     .friseMissionsCard:nth-child(4) {
//       .friseMissionsCardRing {
//         border: 8px solid $color-1;
//       }
//     }
//   }
// }
