.header {
  height: 148px;
  display: flex;
  align-items: center;
  z-index: 1000;
  background-color: $white;
  transition: 0.3s;
  position: relative;
  transition: box-shadow 0.3s;
  user-select: none;
  flex-direction: column;
  .header-inner {
    position: relative;
  }
  .headerBox {
    height: 110px;
    .logoEtSearchBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .MuiButtonBase-root:hover {
        background-color: transparent;
      }
    }
    a {
      border-radius: 0;
      padding: 0;
      img {
        width: 100px;
        max-width: 100%;
        height: 85px;
      }
    }
    .headerNav {
      width: 100%;
      margin-left: 38px;
      nav {
        display: flex;
        padding-top: 16px;
        a {
          font-size: 15px;
          cursor: pointer;
          color: $black;
          margin-right: 15px;
          display: flex;
          align-items: center;
          &:hover {
            color: $color-1;
          }
        }
        div {
          display: flex;
          cursor: pointer;
          a {
            margin: 0;
          }
          svg {
            margin-right: 15px;
          }
        }
      }
    }
    .containAideContactEtMenu {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .expoBtn {
      margin-right: 24px;
      text-align: center;
      a {
        display: block;
        color: $black;
        height: 100%;
        svg {
          height: 30px;
          width: 40px;
        }
        p {
          font-size: 14px;
          line-height: 14px;
        }
        &:hover {
          p {
            color: $color-1;
          }
          svg {
            path {
              fill: $color-1;
            }
          }
        }
      }
    }
    .favorisBtn {
      // height: 83px;
      margin-right: 24px;
      text-align: center;
      height: 100%;
      // display: flex;
      a {
        color: $black;
        height: 100%;
        text-align: center;
        position: relative;
        display: block;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        svg {
          height: 30px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        p {
          font-size: 14px;
          line-height: 14px;
          margin-top: 16px;
        }
        &:hover {
          p {
            color: $color-1;
          }
          svg {
            path {
              fill: $color-1;
            }
          }
        }
      }
    }
    .aideEtContactBtn {
      position: relative;
      text-align: center;
      margin-right: 24px;
      cursor: pointer;
      svg {
        height: 30px;
        width: 40px;
      }
      p {
        color: $black;
        font-size: 14px;
        line-height: 14px;
      }
      &:hover {
        p {
          color: $color-1;
        }
        svg {
          path {
            stroke: $color-1;
          }
        }
        .nav-subheader {
          display: block;
        }
      }
      .nav-subheader {
        display: none;
        position: absolute;
        border-radius: 16px;
        background-color: $white;
        padding: 16px;
        text-align: left;
        list-style: none;
        width: 200px;
        left: 50%; /* à 50%/50% du parent référent */
        transform: translate(-50%, 0); /* décalage de 50% de sa propre taille */
        box-shadow: 0px 5px 10px rgb(140 140 140 / 28%);
        // top: 72px;
        p {
          font-size: 14px;
          font-weight: 600;
          font-family: $HB;
          color: $black;
        }
        a {
          font-size: 14px;
          color: $color-1;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        li {
          &::after {
            content: "";
            display: block;
            position: relative;
            margin-top: 8px;
            margin-bottom: 8px;
            border-bottom: 1px solid #f4f4f4;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
        }
        .hideDesktop {
          display: none;
        }
      }
    }

    .headerPhone {
      margin-right: 35px;
      text-align: center;
      height: 100%;
      a {
        color: $color-1;
        font-size: 18px;
        transition: font-weight 0s;
        &:hover {
          font-weight: 600;
        }
      }
    }
    .menuButton {
      text-align: center;
      cursor: pointer;
      button {
        padding: 0;
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
  .universOverlay {
    position: absolute;
    bottom: -120px;
    left: 50%;
    transform-origin: top;
    transform: translate(-50%) rotateX(90deg);
    overflow: hidden;
    // height: 120px;
    transition: transform 0.3s;
    pointer-events: none;
    background-color: $white;
    border: 1px solid $color-3;
    width: 100%;
    a {
      width: auto;
      height: 110px;
      margin: 8px 0;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: #f9f7f4;
        border-radius: 8px;
      }
      picture {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        align-items: center;
        justify-content: center;
        display: flex;
        position: relative;
        z-index: 9;
      }
      img {
        height: 24px;
      }
      p {
        margin-top: 8px;
        font-size: 11px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
    .containerUniversHeader {
      justify-content: center;
      height: 100%;
      overflow-y: auto;
      white-space: nowrap;
      display: flex;
      padding-bottom: 0;
    }
  }
  .open {
    transform: translate(-50%) rotateX(0deg);
    pointer-events: all;
  }
}
.headerFixed {
  height: 145px;
  width: 100%;
  background-color: $white;
  position: fixed;
  box-shadow: 0px 5px 10px rgb(140 140 140 / 28%);

  .headerBox {
    a {
      img {
        height: 68px;
      }
    }
    .favorisBtn {
      margin-top: -5px;
    }
    .aideEtContactBtn{
      margin-right: 16px;
    }
  }
}
