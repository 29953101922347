.devisContainer {
  .devisForm {
    margin-top: 70px;
    margin-bottom: 70px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px 20px;
    .devisField {
      label {
        font-family: $HB !important;
        span {
          font-weight: bold;
          color: orangered;
        }
      }
      p {
        color: $black;
        letter-spacing: 0px;
      }
      input,
      textarea,
      select {
        margin-top: 10px;
        background-color: #f5f6f6;
        width: 100%;
        height: 50px;
        border-radius: 8px;
        font-size: 16px;
        padding: 0 20px;
        &:focus {
          border: 1px solid $color-1;
        }
      }
      // input {
      //   &:focus {
      //     border: solid 1px $color-1;
      //   }
      //   &[type="text"]:invalid:focus,
      //   &[type="tel"]:invalid:focus,
      //   &[type="number"]:invalid:focus,
      //   &[type="email"]:invalid:focus {
      //     border: $error solid 2px;
      //   }
      //   &[type="text"]:valid,
      //   &[type="tel"]:valid,
      //   &[type="number"]:valid,
      //   &[type="email"]:valid {
      //     border: $success solid 2px;
      //   }
      // }
      textarea {
        height: 240px;
        resize: none;
        padding: 20px 20px;
      }
      &:nth-child(1) {
        grid-column: 1 / 3;
      }
      &:nth-child(8) {
        grid-column: 1 / 3;
      }
      &:nth-child(11) {
        grid-column: 1 / 3;
      }
      &:nth-child(12) {
        grid-column: 1 / 3;
      }
      &:nth-child(13) {
        grid-column: 1 / 3;
      }
      &:nth-child(14) {
        grid-column: 1 / 3;
      }
      &:nth-child(15) {
        grid-column: 1 / 3;
      }
      &:nth-child(16) {
        grid-column: 1 / 3;
      }
      &:nth-child(17) {
        grid-column: 1 / 3;
      }
      &:nth-child(18) {
        grid-column: 1 / 3;
      }
      .devisCheckBox {
        display: flex;
        // height: 20px;
        margin-top: 15px;
        input {
          width: 20px;
          height: 20px;
          margin: 0px 10px 0 10px;
          cursor: pointer;
          accent-color: $color-1;
        }
        label {
          font-weight: 100 !important;
        }
      }
      .devisRadio {
        display: flex;
        height: 20px;
        margin-top: 15px;
        div {
          display: flex;
          align-items: center;
          input {
            width: 20px;
            height: 20px;
            margin: 0px 10px 0 10px;
            cursor: pointer;
            accent-color: $color-1;
          }
          label {
            font-weight: 100 !important;
          }
        }
      }
      .devisCheckBoxMosaique {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 70%;
        div {
          display: flex;
          align-items: center;
          height: 20px;
          margin-top: 15px;
          input {
            width: 20px;
            height: 20px;
            margin: 0px 10px 0 10px;
            cursor: pointer;
            accent-color: $color-1;
          }
          label {
            font-weight: 100 !important;
          }
        }
      }
      .devisSelect option {
        background-color: #f5f6f6;
      }
      .devisFile {
        position: relative;
        height: 110px;
        .devisFileOverlay {
          position: absolute;
          background-color: #f5f6f6;
          height: 100%;
          width: 100%;
          margin-top: 10px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          pointer-events: none;
        }
        input {
          cursor: pointer;
          height: 100%;
        }
      }
      input[type="submit"] {
        text-transform: none;
        height: 40px;
        margin-top: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        color: $white;
        font-family: $HR;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 1.5px;
        background-color: $darkGrey;
        cursor: pointer;
        width: 260px;
        text-align: left;
      }
    }
    #contactFieldSubmit {
      display: flex;
      align-items: center;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      width: 260px;
      span {
        position: absolute;
        right: 10px;
        transform: rotate(-90deg);
        color: $white;
      }
    }
  }
}
.successSending {
  padding: 50px;
  margin: 0 0;
  text-align: center;
  p {
    margin-bottom: 20px;
  }
}
