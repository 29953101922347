// STYLE GLOBAL
.IconButtonFav {
  svg.MuiSvgIcon-root {
    path {
      fill: $darkGrey;
    }
  }
}
.IconButtonFavActive {
  svg.MuiSvgIcon-root {
    path {
      fill: #e52413;
    }
  }
}
.back {
  width: 100%;
  display: flex;
  align-items: center;
  height: 48px;
  nav {
    ol {
      a {
        color: $black !important;
      }
      li:last-child {
        pointer-events: none;
        a {
          font-family: $HB !important;
        }
      }
    }
  }
  .MuiButtonBase-root {
    color: $darkGrey;
    font-family: $HR;
    text-transform: none;
    font-size: 14px;
    &:hover {
      background-color: transparent !important;
      text-decoration: underline;
    }
  }
  .MuiButton-startIcon {
    background-color: $color-4;
    border-radius: 50px;
    padding: 4px;
    width: 23px;
    height: 23px;
    svg {
      font-size: 14px;
      path {
        fill: $darkGrey;
      }
    }
  }
}

.ariane {
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  height: 48px;
  min-height: 48px;
  user-select: none;
  nav {
    ol {
      a {
        color: $black !important;
      }
      li:last-child {
        pointer-events: none;
        a {
          font-family: $HB !important;
        }
      }
    }
  }
}

a.links {
  font-family: $HR;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  text-decoration: underline;
  &:hover {
    color: $color-1;
  }
}

.MuiButton-root.arrowButton {
  text-transform: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  color: $white;
  font-family: $HR;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1.5px;
  span {
    transform: rotate(-90deg);
    color: $white;
  }
}

// Inspiration
.contain-grid-inspiration {
  .item-grid-inspiration {
    &:nth-child(2) {
      margin-top: 32px;
    }
    &:nth-child(4),
    &:nth-child(6) {
      margin-top: -32px;
    }
    &:nth-child(7),
    &:nth-child(9) {
      margin-top: -32px;
    }
  }
}

// INSPIRATION ACCUEIL
.showInspirations {
  text-align: center;
  overflow: hidden;
  .pretitre {
    margin-top: 24px;
    margin-bottom: 48px;
  }
  .item-grid-show-inspiration {
    margin-top: 48px;
  }
  .contain-grid-show-inspiration {
    .MuiGrid-root {
      &:nth-child(even) {
        margin-top: 88px;
      }
    }
  }
}

// BESOIN AIDE
.besoinAideBox {
  background-color: #f9f7f4;
  overflow: hidden;
  .besoinAideCard {
    text-align: center;
    p {
      margin: 24px 0 16px;
      font-size: 24px;
      font-family: $P22;
      font-weight: 500;
    }
    .picture-box {
      position: relative;
      width: 100%;
      height: 58px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    img {
      position: absolute;
    }
  }
}

// Swiper product accueil
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev,
.swiper-button-next {
  color: $color-1;
  &::after {
    font-size: 14px;
  }
}

// SUBFOOTER
.subFooter {
  width: 100%;
  display: flex;
  .subFooterIndex {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9;
    padding: 0 20px;
    h2 {
      max-width: 720px;
      margin-bottom: 32px;
    }
  }
  .subFooterLeft {
    height: 400px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    &::before {
      clear: both;
    }
    &::before {
      content: "";
      background-color: $black;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.2;
    }
    h2 {
      text-align: center;
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6);
      color: $white;
      margin-bottom: 24px;
      text-align: center;
    }
    p {
      color: $white;
      margin-bottom: 32px;
      text-align: center;
      max-width: 540px;
    }
    button {
      background-color: $darkGrey;
      color: $white;
      padding: 15px;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    }
  }
  .subFooterRight {
    background-color: $color-3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 24px;
    height: 400px;
    h2 {
      color: $black;
      margin-bottom: 24px;
      text-align: center;
    }
    p {
      color: $black;
      margin-bottom: 32px;
      max-width: 320px;
      text-align: center;
    }
    button {
      background-color: $darkGrey;
      color: $white;
      padding: 15px;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    }
  }
  .subFooterRightCitation {
    background-color: rgba(0, 131, 62, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    .subFooterIndex {
      position: relative;
      padding: 0 24px;
      max-width: 540px;
      p {
        font-style: italic;
        font-family: $HR;
        font-size: 32px;
        text-align: center;
        line-height: 42px;
      }
      &::before {
        content: "";
        position: relative;
        display: block;
        background-image: url("../assets/citation.svg");
        width: 114px;
        height: 83px;
        position: absolute;
        z-index: -1;
        top: 0%;
        left: 50%; /* à 50%/50% du parent référent */
        transform: translate(
          -50%,
          -50%
        ); /* décalage de 50% de sa propre taille */
      }
    }
  }
}

// disposition de la data
.pagination {
  display: flex;
  justify-content: center;
}

.pageSizeSelect {
  padding: 10px;
  cursor: pointer;
  border: 1px solid #f9f7f4;
  border-radius: 10px;
}

// SERVICE
.bannerService {
  min-height: 641px;
  height: 70vh;
  background: rgb(238, 245, 241);
  background: linear-gradient(
    180deg,
    rgba(238, 245, 241, 1) 0%,
    rgba(238, 245, 241, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  color: $black;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  h1 {
    margin-bottom: 35px;
  }
  .imgBanner {
    position: absolute;
    top: 18%;
    right: 0;
    width: 100%;
    max-width: 420px;
    object-fit: cover;
    height: 420px;
  }
  .imgBanner2 {
    position: absolute;
    bottom: 22%;
    left: 45%;
  }
}

.imgSchemas{
  padding-top: 120px;
}

.boxCardService {
  background-color: $color-4;
  border: 1px solid $color-4;
  padding: 0 24px;
  min-height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  .titreBoxCardService {
    font-family: $P22;
    font-size: 24px;
    text-align: center;
    margin-bottom: 16px;
  }
  p {
    text-align: center;
  }
  &:hover {
    background-color: $white;
    border: 1px solid $color-1;
    color: $color-1;
  }
}

.modalService {
  .boxModalService {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    border: 2px solid #000;
    height: 70%;
    display: block;
    border-width: 0;
    padding: 24px 10px 0;
    width: 90% !important;
    height: 85%;
    background-color: transparent;
    box-shadow: none;
  }
  .ButtonSwiperClose {
    background-color: $color-1;
    position: absolute;
    top: 0;
    z-index: 99;
    right: 0;
    svg {
      path {
        fill: #fff;
      }
    }
  }
  .contentModal {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
    display: flex;
    padding: 24px;
    max-height: 100%;
  }
  .contentModalText {
    padding-left: 24px;
    width: 70%;
  }
  .contentModalImg {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    img {
      width: 100%;
      height: auto;
      max-height: 320px;
    }
  }
}

.cookieOverlay {
  // background-color: rgba(0, 0, 0, 0.547);
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  pointer-events: none;
  .cookieModal {
    pointer-events: all;
    background-color: white;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.354);
    position: relative;
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
      width: 70%;
    }
    div {
      width: auto;
      display: flex;
      justify-content: space-evenly;
      button {
        width: auto;
        font-size: 16px;
        padding: 16px 30px;
        border-radius: 10px;
        font-weight: bold;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
          rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
          rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
        cursor: pointer;
        &:nth-child(1) {
          background-color: $color-1;
          color: white;
          margin-right: 20px;
        }
        &:nth-child(2) {
          background-color: $white;
        }
      }
    }
    .closeCookieBtn {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}

// SERVICES
.imgSchemas {
  width: 100%;
  object-fit: contain;
  &.hideDesktop {
    display: none;
  }
}

// 404
.container_errorpage {
  background: rgb(238, 245, 241);
  background: linear-gradient(
    180deg,
    rgba(238, 245, 241, 1) 0%,
    rgba(238, 245, 241, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}
.errorPage {
  // height: 86vh;
  min-height: 768px;
  h1 {
    font-size: 180px;
    color: $color-1;
    line-height: normal;
    height: auto;
  }
  p {
    font-size: 32px;
    line-height: normal;
  }
}
