.container_card_inspiration {
  border-radius: 16px;
  box-shadow: none;
  position: relative;
  background-color: $color-2;
  img {
    width: 100%;
    height: 320px;
    border-radius: 16px;
    object-fit: cover;
  }
  .card-content {
    display: none;
    position: absolute;
    bottom: 0;
    color: $white;
    width: 100%;
    padding: 20px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  &:hover {
    img {
      box-shadow: 0px 5px 10px rgb(140 140 140 / 28%);
    }
    .card-content {
      display: block;
      background-color: $darkGrey;
    }
  }
}

.card-inspiration {
  position: relative;
  .bannerAddToFav {
    background-color: white;
    position: absolute;
    top: 8px;
    border-radius: 4px;
    right: 8px;
    z-index: 1;
  }
}
