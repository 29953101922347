.productStep {
  width: 100%;
  height: 720px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .productStepBox {
    padding: 15px;
    background-color: #f9f7f4;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
      text-align: center;
      margin-bottom: 24px;
    }
    p {
      text-align: center;
      margin-bottom: 32px;
    }
    button {
      background-color: $darkGrey;
      text-transform: none;
    }
  }
  .productStepSwiper {
    position: absolute;
  }
  .swiper.imgSwiperContainer_1 {
    left: 0;
    top: 0;
    width: 240px;
    height: 230px;
  }
  .swiper.imgSwiperContainer_2 {
    left: 50%;
    top: 0;
    width: 162px;
    height: 174px;
    transform: translate(-50%, 0%);
  }
  .swiper.imgSwiperContainer_3 {
    right: 0;
    top: 32px;
    width: 198px;
    height: 216px;
  }
  .swiper.imgSwiperContainer_4 {
    left: 0;
    bottom: 54px;
    width: 192px;
    height: 208px;
  }
  .swiper.imgSwiperContainer_5 {
    left: 30%;
    bottom: 0;
    width: 162px;
    height: 174px;
    transform: translate(-10%, 0%);
  }
  .swiper.imgSwiperContainer_6 {
    right: 0;
    bottom: 0;
    width: 240px;
    height: 230px;
  }
}
