.picturePopUpBackdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.806);
  z-index: 15;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  //   display: none;
  .picturePopUp {
    width: 70%;
    height: 70%;
    border: 2px solid $color-1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
