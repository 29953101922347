.bannerRecherche {
  background-color: $color-2;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  width: 100%;
  h1{
    max-width: 70%;
    text-align: center;
  }
}
.rechercheResultCard {
  border-radius: 16px;
  height: 100%;
  padding: 20px;
  width: 100%;
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.078);
    p{
      color: $color-1;
    }
  }
  a {
    height: 100%;
    width: 100%;
    section {
      // height: 300px;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    p{
      font-weight: bold;
      transition: 0.2s color;
    }
  }
}
