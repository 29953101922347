// liste des réalisations
.realisationsContainer {
  .realisationBannerContainer {
    position: relative;
    .postionAbsRight {
      position: absolute;
      top: -2%;
      right: -5%;
      .imgContainerPosition {
        display: flex;
        align-items: center;
        gap: 16px;
      }
      .imgPositionAbsLeft {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-end;
      }
      .imgPositionAbsRight {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
    .postionAbsLeft {
      position: absolute;
      top: -2%;
      left: -2%;
      .imgContainerPosition {
        display: flex;
        align-items: center;
        gap: 16px;
      }
      .imgPositionAbsLeft {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-end;
      }
      .imgPositionAbsRight {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
    .contentAbsoluteReaBanner {
      position: absolute;
      top: 0;
      .divContentAbsoluteBanner {
        display: flex;
      }
      &.bannerLeft {
        left: 0;
      }
      &.bannerRight {
        right: 0;
      }
    }
    .square {
      position: relative;
      width: 200px;
      height: auto;
      display: block;
      margin: 4px;
      &::after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
      .imgRealisation {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        border-radius: 20px;
        margin: 8px;
      }
    }
  }
  .firstRealisationBox {
    width: 100%;
    max-height: 600px;
    margin-top: 60px;
    display: flex;
    align-items: center;
    .firstRealisationLeft {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .bannerAddToFav {
        width: auto;
      }
      .firstRealisationLeftDiv {
        width: 70%;
        p,
        a {
          margin-bottom: 20px;
        }
      }
      .containBannerAddToFav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
        position: relative;
        p {
          margin-bottom: 0;
        }
      }
    }
    .firstRealisationRight {
      position: relative;
      width: 50vw;
      max-height: 600px;
      background-color: $color-2;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }
      &::after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }
  }
  .realisationsUniversFilterBox {
    margin-top: 96px;
    min-height: 77px;
    height: 100%;
    max-height: 100%;
    padding: 14px 16px;
    text-align: center;
    background-color: $white;
    border-radius: 16px;
    z-index: 9;
    position: relative;
    width: 100%;
    .realisationsUniversFilter {
      display: flex;
      justify-content: center;
      overflow-y: auto;
      white-space: nowrap;
      .realisationsUniversFilterCard {
        height: 110px;
        margin: 8px 0;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background-color: #f9f7f4;
          border-radius: 8px;
        }
        picture {
          width: 40px;
          height: 40px;
          border-radius: 100px;
          align-items: center;
          justify-content: center;
          display: flex;
          position: relative;
          z-index: 9;
        }
        img {
          height: 24px;
        }
        p {
          margin-top: 8px;
          font-size: 11px;
          text-align: center;
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      .selectedUniverFilter {
        background-color: #f9f7f4;
        border-radius: 8px;
      }
      // &:hover {
      //   // picture {
      //   //   &::after {
      //   //     transition: 0.2s;
      //   //     position: absolute;
      //   //     content: "";
      //   //     display: block;
      //   //     background-color: $color-1;
      //   //     opacity: 0.15;
      //   //     width: 40px;
      //   //     height: 40px;
      //   //     border-radius: 100px;
      //   //     top: 0%;
      //   //     left: 50%; /* à 50%/50% du parent référent */
      //   //     transform: translate(
      //   //       -50%,
      //   //       0%
      //   //     ); /* décalage de 50% de sa propre taille */
      //   //   }
      //   // }
      //   // p {
      //   //   color: $color-1;
      //   // }
      // }
    }
  }
  .realisationsUniversGaleryBox {
    .realisationsUniversGalery {
      .realisationsUniversGaleryCard {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        position: relative;
        .bannerAddToFav {
          background-color: white;
          position: absolute;
          top: 24px;
          border-radius: 4px;
          right: 14px;
          z-index: 9;
          span::before {
            display: none;
          }
        }
        a {
          height: 100%;
          width: 100%;
          .realisationsUniversGaleryCardImg {
            position: relative;
            width: 100%;
            max-height: 353px;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            img {
              position: absolute;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-top-left-radius: 16px;
              border-top-right-radius: 16px;
            }
            &::after {
              content: "";
              display: block;
              padding-bottom: 100%;
            }
          }
          .realisationsUniversGaleryCardText {
            height: 147px;
            padding: 20px 0 0 20px;
            span {
              position: relative;
              text-transform: uppercase;
            }
            p {
              margin-top: 10px;
              font-family: $P22 !important;
              font-weight: 600;
            }
          }
          &:hover {
            span {
              display: flex;
              align-items: center;
              &::before {
                content: "";
                background-image: url("../assets/horizontalRule.svg");
                width: 33px;
                height: 2px;
                display: block;
                position: relative;
                margin-right: 8px;
              }
            }
            .realisationsUniversGaleryCardImg {
              &::before {
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
                clear: both;
                content: "";
                background-color: $black;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                opacity: 0.3;
              }
            }
          }
        }
      }
    }
  }
  .realisationsAvisBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
    margin-top: 70px;
    .realisationsAvis {
      width: 85%;
    }
  }
}

.containerItemImageTextRealisation {
  display: flex;
  align-items: center;
}
.itemImageRealisation {
  img {
    width: auto;
    max-width: 100%;
    height: 230px;
    max-height: 100%;
  }
  p {
    text-align: center;
    margin-bottom: 16px;
  }
}
.containItemTextRealisation {
  display: flex;
  justify-content: flex-end;
}
.itemTextRealisation {
  display: flex;
  max-width: 640px;
  justify-content: flex-end;
  p {
    font-family: $P22 !important;
    margin-bottom: 24px;
    h2 {
      margin-bottom: 42px;
      span {
        font-size: 38px;
        line-height: 48px;
        font-family: $P22 !important;
      }
      strong {
        font-family: $P22 !important;
      }
    }
    span {
      font-family: $HR !important;
    }
  }
}
.itemCouvertureRealisationContainer {
  height: 650px;
  width: 100%;
  margin-bottom: 90px;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .itemCouvertureRealisationPinsBox {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    .itemCouvertureRealisationPinsCanvas {
      position: relative;
      height: 100%;
      width: 100%;
      .itemCouvertureRealisationPins {
        position: absolute;
        background-color: #ffffff;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: solid 7px rgba(255, 255, 255, 0.267);
        transition: outline 0.3s;
      }
    }
  }
  .pinsPopupOverlay {
    opacity: 0;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.479);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 15;
    cursor: pointer;
    .pinsPopup {
      cursor: auto;
      background-color: $white;
      height: 260px;
      width: 210px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .pinsPopupHeader {
        width: 100%;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 5px 5px 0 0;
        button {
          cursor: pointer;
          padding: 0;
        }
      }
      .pinsPopupContent {
        width: 100%;
        height: 169px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .pinsPopupContentTitle {
          width: 90%;
          height: 67px;
          display: flex;
          justify-content: center;
          overflow: hidden;
        }
        .pinsPopupContentImg {
          width: 90%;
          height: 100px;
          object-fit: contain;
          position: relative;
          top: 0;
          left: 0;
          transform: none;
        }
      }
      .pinsPopupAction {
        width: 90%;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          background-color: $black;
          color: $white;
          width: 100%;
          height: 60%;
          border-radius: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .onPopup {
    animation: onPopup 0.8s 0s ease-in forwards;
  }
}

.proposeRealisationContainer {
  background-color: #f9f7f4;
  padding-left: 15%;
  padding-top: 64px;
  padding-bottom: 56px;
  position: relative;
  h2{
    position: relative;
  }
  .proposeRealisationCarousel {
    position: relative;
    padding-top: 64px;
    // height: 680px;
    background-color: transparent;
    .swiper-slide {
      background-color: transparent;
    }
    .swiper-button-next {
      margin-right: 15px;
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: 5%;
      background-color: #ffffff;
      border-radius: 50px;
      height: 29px;
      width: 29px;
      color: $black;
      box-shadow: 0px 5px 10px rgb(140 140 140 / 28%);
    }
    .swiper-button-prev {
      left: 92%;
    }
  }
  .proposeRealisationCard {
    //   width: 360px;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    //   overflow: hidden;
    .actuCardImg {
      height: 100%;
      width: 100%;
      position: relative;
    }
    img {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      height: 350px;
    }
    .proposeRealisationCardNotice {
      min-height: 140px;
      text-align: start;
      padding: 20px;
      span.pretitre {
        font-size: 14px;
        text-transform: uppercase;
        color: $color-1;
      }
      p {
        margin-top: 10px;
        font-size: 18px;
        font-family: $P22;
        font-weight: 700;
      }
    }
    a {
      &:hover {
        .actuCardImg {
          &::before {
            clear: both;
            content: "";
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            background-color: $black;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0.3;
            left: 0;
          }
        }
        .pretitre {
          display: flex;
          align-items: center;
          &::before {
            content: "";
            background-image: url("../assets/horizontalRule.svg");
            width: 33px;
            height: 2px;
            display: block;
            position: relative;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

// .proposeRealisationCard {
//   background-color: rgba(255, 0, 0, 0.113);
//   border-top-right-radius: 15px;
//   border-top-left-radius: 15px;
//   width: 350px !important;
//   img {
//     height: 350px !important;
//     object-fit: cover !important;
//     border-top-left-radius: 15px;
//     border-top-right-radius: 15px;
//   }
// }
