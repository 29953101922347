@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.animateSkeleton {
  animation: 1s infinite fadeIn alternate;
}

.skeletGrid {
  div {
    div {
      background-color: $color-3;
      animation: 1s infinite fadeIn alternate;
    }
  }
}

.productCardSkelet {
  height: 310px;
  background-color: $color-3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  .pictureProductCardSkelet {
    background-color: $color-2;
    width: 90%;
    height: 60%;
  }
  .titleProductCardSkelet {
    width: 90%;
    height: 10%;
    background-color: $color-2;
  }
  .contentProductCardSkelet {
    width: 90%;
    height: 20%;
    background-color: $color-2;
  }
}

.productViewSkelet {
  div {
    background-color: $color-3;
    animation: 1s infinite fadeIn alternate;
  }
}

.associatesProductSkelet {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: space-between;
  .associatesProductCardSkelet {
    width: 24%;
    height: 100%;
    background-color: $color-2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    div:nth-child(1) {
      background-color: $color-3;
      width: 90%;
      height: 60%;
    }
    div:nth-child(2) {
      background-color: $color-3;
      width: 90%;
      height: 20%;
    }
  }
}
