.cataloguesContainer {
  .catalogueSearchBar {
    position: relative;
    z-index: 10;
    margin-bottom: 30px;
    background-color: #ffffff;
    -webkit-user-select: none;
    user-select: none;
    padding: 20px;
    box-shadow: 0px 10px 10px rgb(0 0 0 / 5%);
    border-radius: 10px;
    width: 100%;
    .searchCatalogueBarBox {
      width: 100%;
      .searchCatalogueBarInput {
        width: 100%;
        height: 40px;
        padding: 0 25px;
        background-color: #f5f6f6;
        z-index: 10;
        font-size: 16px;
        color: $black;
        border-radius: 7px;
        transition: box-shadow 0.2s ease-in-out;
        position: relative;
        &::placeholder {
          font-size: 14px;
          font-family: $HR;
          color: rgba(0, 0, 0, 0.33);
        }
      }
    }
    section {
      display: flex;
      justify-content: center;
      align-items: center;
      .containerBtnUniversCategories {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btnUniversFiltersSearch {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        color: $light-grey;
        cursor: pointer;
        img.imgUniversIcon {
          height: 24px;
        }
        img {
          height: 30px;
        }
        div {
          display: flex;
          // margin-top: 10px;
          p {
            font-size: 14px;
            display: flex;
            span {
              font-size: 13px;
            }
          }
          svg {
            fill: $light-grey;
          }
          .turnArrow {
            transform: rotate(180deg) !important;
          }
        }
      }
    }
    .universFilterList {
      background-color: $white;
      width: 100%;
      z-index: 10;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-gap: 10px;
      grid-auto-rows: minmax(100px, auto);
      height: 0px;
      overflow: hidden;
      transition: height 0.3s;
      .universFilterListCard {
        height: 90px;
        margin: 8px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background-color: #f9f7f4;
          border-radius: 8px;
        }
        picture {
          width: 40px;
          height: 40px;
          border-radius: 100px;
          align-items: center;
          justify-content: center;
          display: flex;
          position: relative;
          z-index: 9;
        }
        img {
          height: 24px;
        }
        p {
          margin-top: 8px;
          font-size: 10px;
          text-align: center;
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      .universFilterListCardActive{
        background-color: red;
      }
    }
    .universFilterListOpen {
      height: 100px;
    }
    .wrapperUniversFilter {
      display: flex;
      align-items: center;
    }
    .categoryFilterList {
      background-color: $white;
      width: 100%;
      height: 0;
      transition: height 0.3s;
      overflow: hidden;
      z-index: 10;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 10px;
      grid-auto-rows: minmax(100px, auto);
      .categoryFilterListCard {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .categoryFilterListCardImg {
          background-color: #f9f7f4;
          height: 110px;
          max-height: 100%;
          cursor: pointer;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            padding: 10px;
            width: 130px;
            height: 110px;
            object-fit: cover;
          }
        }
        p {
          margin-top: 18px;
          text-transform: uppercase;
          font-size: 11px;
          cursor: pointer;
          text-align: center;
          line-height: 15px;
        }
      }
    }
    .categoryFilterListOpen {
      height: 160px;
    }
  }
  .cataloguesTypeBox {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 100px 0 100px 80px;
    h3 {
      position: absolute;
      left: -7%;
      top: 46%;
      transform: rotate(-90deg);
      font-size: 24px;
    }
    .cataloguesGrid {
      width: 100%;
      .MuiLink-root {
        text-decoration: none;
        cursor: pointer;
        img {
          transition: all 0.3s ease-in;
          border: 2px solid $color-4;
          object-fit: contain;
        }
        &:hover {
          img {
            animation: bouncing 0.5s 0.3s;
          }
        }
      }
      @keyframes bouncing {
        from,
        to {
          transform: scale(1, 1);
        }
        25% {
          transform: scale(0.9, 1.1);
        }
        50% {
          transform: scale(1.1, 0.9);
        }
        75% {
          transform: scale(0.95, 1.05);
        }
      }
      div {
        display: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          height: 240px;
          max-width: 240px;
          object-fit: cover;
        }
        span {
          margin-top: 16px;
          margin-bottom: 8px;
          text-align: center;
        }
        p {
          width: 90%;
          text-align: center;
          color: $black;
        }
      }
    }
    &.cataloguesTypeBoxMiseAvant {
      .cataloguesGrid {
        img {
          height: 387px;
        }
      }
    }
  }
}
