.productViewContainer {
  .productViewPictures {
    width: 100%;
  }
  .swiper {
    .productViewSliderPictures {
      // height: 168px;
      // width: 168px;
      div {
        width: 100%;
        cursor: pointer;
        img,
        picture {
          height: 168px;
          border-radius: 8px;
          width: 100%;
        }
      }
    }
  }
  .productViewBlock {
    .MuiPaper-root {
      background-color: rgb(255, 255, 255);
      color: black;
      box-shadow: none;
      position: relative;
      transition: none;
      overflow-anchor: none;
      border-radius: 0px;
      .MuiTypography-root {
        margin-right: 10px;
        font-size: 14px;
        font-family: $HSB;
        color: #000000;
        letter-spacing: 1.2px;
      }
    }
  }
  .productViewPrimaryPictures {
    position: relative;
    overflow: hidden;
    height: 640px;
    background-color: $white;
    margin-bottom: 30px;
    cursor: zoom-in;
    img {
      border-radius: 8px;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
    .productAddToFavorites {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 50;
    }
    .magnifier {
      height: inherit !important;
      border-radius: 8px;
      width: 100%;
    }
  }
  .productViewMoreInfos {
    padding-top: 24px;
  }
  .productViewSecondaryAccordion.MuiPaper-root {
    background-color: rgb(255, 255, 255) !important;
    color: black !important;
    box-shadow: none !important;
    position: relative;
    transition: none;
    overflow-anchor: none;
    border-radius: 0px;
    .MuiSvgIcon-root {
      fill: $color-1;
    }
    .MuiTypography-root {
      font-size: 16px;
      font-family: $HSB;
      color: black;
    }
    .MuiAccordionSummary-root.Mui-expanded {
      border-bottom: 1px solid #f4f4f4;
      margin-bottom: 16px;
    }
    .MuiAccordionDetails-root {
      padding: 0;
    }
    .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
      box-shadow: none !important;
    }
    li {
      list-style-type: none;
      font-size: 14px;
    }
    a:hover {
      color: $color-1;
      text-decoration: underline;
    }
    &.infospratiques {
      tbody {
        th {
          display: flex;
          align-items: center;
        }
        tr {
          &:nth-child(odd) {
            background: #f4f4f4;
          }
          &:last-child {
            th,
            td {
              border-bottom: none;
            }
          }
          td {
            font-weight: 600;
          }
        }
      }
      .utileNotice {
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;
        .MuiSvgIcon-root {
          fill: #d3e4da;
        }
      }
    }
    .stylePDetails {
      font-size: 16px;
      font-family: $HR;
    }
    &.noticemontagetuto {
      .productTutosContainer {
        &:before {
          display: block;
          position: relative;
          content: "";
          border-top: 1px solid #f4f4f4;
          margin-bottom: 24px;
          margin-top: 24px;
        }
      }
      .productNoticeMontage {
        margin-bottom: 8px;
        list-style-type: none;
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
        }
      }
      .noticeTutosCard {
        display: flex;
        flex-direction: column;
        padding: 0 0 0 10px;
        .noticeTutosCardHeader {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          margin-top: 20px;
          img {
            margin-right: 8px;
          }
        }
        .noticeTutosCardsLine {
          display: flex;
          .tutoCallBtn {
            max-width: 150px;
            text-align: center;
            cursor: pointer;
            &:hover {
              p {
                text-decoration: underline;
              }
            }
          }
          .tutoPlayerIconProduct {
            background-color: $color-1;
            width: 70px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin: 0 auto;
            margin-bottom: 10px;
            &:hover {
              box-shadow: 0px 0px 5px rgb(143, 143, 143);
            }
            svg {
              fill: $white !important;
            }
            img {
              width: 70px;
              height: 70px;
              object-fit: cover;
            }
          }
        }
        a {
          max-width: 150px;
          text-align: center;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
            img {
              box-shadow: 0px 0px 5px rgb(143, 143, 143);
            }
          }
        }
      }
      a {
        text-decoration: underline;
        justify-content: space-between;
        font-size: 14px;
        img {
          height: 100px;
        }
      }
      .tutoStyleLine {
        display: flex;
        justify-content: space-between;
        width: 40%;
      }
      .tutoStyleNotice {
        cursor: pointer;
        text-decoration: underline;
        &:hover {
          color: $color-1;
        }
      }
      a:hover {
        color: $color-1;
      }
    }
    .catalogueListImg {
      display: flex;
      padding: 0;
      a {
        max-width: 150px;
        text-align: center;
        &:hover {
          img {
            box-shadow: 0px 0px 5px rgb(143, 143, 143);
          }
        }
      }
      img {
        height: 100px;
        object-fit: contain;
      }
    }
    .detailEtConseilBox {
      padding: 0;
      .detailEtConseil {
        padding-left: 20px;
      }
    }
  }
  .OverlayPictureProduct {
    opacity: 0%;
    transition: opacity 0.3s;
    pointer-events: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.701);
    height: 100%;
    width: 100%;
    z-index: 1500;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      background-color: rgb(255, 255, 255);
      height: 80%;
      width: 80%;
      position: relative;
      .swiperZoomBox {
        width: 100%;
        height: 100%;
        .swiper-wrapper {
          width: 100%;
          height: 100%;
        }
        .swiperSlideZoomBox {
          width: 100%;
          height: 100%;
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .closePictureProduct {
      position: absolute;
      height: 50px;
      width: 50px;
      background-color: $color-1;
      border-radius: 50%;
      top: 20px;
      right: 20px;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1501;
    }
  }
  .showModal {
    opacity: 100%;
    pointer-events: all;
  }
  .productViewInfos {
    h2 {
      font-family: $HSB !important;
      font-size: 24px;
      // margin-bottom: 0;
      line-height: normal;
    }
    .sub_category_title {
      font-family: $HR;
      color: #909090;
      font-size: 14px;
    }
    .dFlexProductFav {
      display: flex;
      position: relative;
      h2 {
        max-width: 420px;
      }
      .productAddToFavorites {
        position: absolute;
        top: -16px;
        right: 0;
      }
    }
  }
  .productViewColorPicker {
    .productSecondarColor {
      margin-top: 20px;
      p {
        color: $black;
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 16px;
      }
    }
    .productSecondarPart {
      font-family: $HB !important;
      color: $black;
    }
    p {
      font-family: $HB !important;
      margin-bottom: 10px;
    }
    section {
      display: flex;
    }
    .productViewColorGomette {
      margin-top: 8px;
      margin-bottom: 8px;
      position: relative;
      cursor: pointer;
      .productViewColorName {
        position: absolute;
        color: #909090;
        background-color: white;
        padding: 8px;
        border-radius: 8px;
        font-size: 14px;
        color: $grey;
        font-family: $HR !important;
        box-shadow: 0px 5px 10px rgb(140 140 140 / 28%);
        top: 50%;
        left: 50%; /* à 50%/50% du parent référent */
        transform: translate(
          -50%,
          50%
        ); /* décalage de 50% de sa propre taille */
        display: none;
        z-index: 99;
        width: max-content;
      }
      .productViewColorViewer {
        margin-right: 10px;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.154);
      }
      .productViewMatiereViewer {
        margin-right: 10px;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border: 0.5px solid $light-grey;
      }
      &:hover {
        .productViewColorName {
          display: block;
        }
      }
    }
  }
  .productViewSizePicker {
    p {
      text-decoration: underline;
      color: $light-grey;
      font-family: $HR;
      cursor: pointer;
      &:hover {
        color: $color-1;
      }
    }
    a {
      text-decoration: underline;
      color: $light-grey;
      font-family: $HR;
      cursor: pointer;
      &:hover {
        color: $color-1;
      }
    }
    p:nth-child(1) {
      font-family: $HB !important;
      margin-bottom: 12px;
      text-decoration: none;
      color: $black;
      cursor: auto;
    }
  }

  .productViewDevisDemande {
    a {
      margin-top: 24px;
    }
  }
  .productViewOption {
    .productViewOptionTitle {
      font-family: $HB !important;
      margin-bottom: 12px;
    }
    &::before {
      content: "";
      display: block;
      position: relative;
      border-top: 1px solid #f4f4f4;
      margin-bottom: 16px;
      margin-top: 16px;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    background-color: $color-1;
    color: $white;
    border-radius: 50px;
    height: 32px;
    width: 32px;
    top: 50%;
    transform: translate(0%, -50%);
    margin: auto;
  }
}

.productViewColorPickerSummary {
  margin-top: 16px;
}

#productViewDescription {
  border-top: 1px solid #f4f4f4;
  margin: 16px 0;
  padding: 16px 0;
}
.productViewDescriptionSummary.css-1elwnq4-MuiPaper-root-MuiAccordion-root,
.productViewSizePickerSummary.css-1elwnq4-MuiPaper-root-MuiAccordion-root,
.productViewColorPickerSummary.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none;
  a {
    margin-bottom: 8px;
    &:hover {
      color: $color-1;
      text-decoration: underline;
    }
    &.active {
      color: $color-1;
      text-decoration: underline;
    }
  }
  .MuiTypography-root {
    font-size: 14px;
    font-family: $HSB !important;
    color: $black;
    letter-spacing: 1.2px;
  }
  .MuiAccordionDetails-root {
    p {
      font-size: 14px;
    }
  }
  .css-1aqg2al-MuiAccordionDetails-root {
    padding: 0 0 24px;
  }
  .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin-top: 20px;
    margin-bottom: 0;
  }
  &::before {
    background-color: transparent !important;
  }
  .titrePartieModifiable {
    margin-top: 8px;
    margin-bottom: 4px;
  }
}
.declinaisonLink {
  &:hover {
    color: $color-1;
  }
}

#zoomBox {
  height: auto;
  width: 90%;
  max-width: 1440px;
  .ButtonSwiperClose{
    background-color: $color-1;
    position:  absolute;
    top: -22px;
    z-index: 99;
    right: -10px;
    svg{
      path{
        fill: #fff;
      }
    }
  }
  picture {
    height: 100%;
  }
  img {
    height: 100%;
    object-fit: contain;
  }
}
