.bannerEntreprise {
  min-height: 480px;
  padding-top: 96px;
  height: 100%;
  background: rgb(238, 245, 241);
  background: linear-gradient(
    180deg,
    rgba(238, 245, 241, 1) 0%,
    rgba(238, 245, 241, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  color: $black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    margin-bottom: 35px;
  }
}
.entrepriseContainer {
  position: relative;
  .entrepriseBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .entrepriseMap {
      h3 {
        font-family: $HR !important;
        font-size: 32px;
        max-width: 330px;
        text-align: center;
        margin: 0 auto;
      }
    }
    .entrepriseTimeline {
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .line {
        height: 2px;
        width: 100%;
        background: rgb(230, 230, 230);
        position: relative;
        .dot-1 {
          height: 20px;
          width: 20px;
          position: absolute;
          border-radius: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: $color-1;
          left: 10%;
        }
        .dot-2 {
          height: 20px;
          width: 20px;
          position: absolute;
          border-radius: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: $color-1;
          left: 36%;
        }
        .dot-3 {
          height: 20px;
          width: 20px;
          position: absolute;
          border-radius: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: $color-1;
          left: 75%;
        }
        .step-1 {
          position: absolute;
          top: -70px;
          transform: translate(-50%, -50%);
          left: 24%;
          width: 30%;
          p {
            &:nth-child(1) {
              color: $color-1;
              font-weight: bold;
              font-size: 30px;
              margin-bottom: 5px;
            }
            &:nth-child(2) {
              span {
                color: black;
                font-weight: bold;
                letter-spacing: 0px;
              }
            }
          }
        }
        .step-2 {
          position: absolute;
          top: 70px;
          transform: translate(-50%, -50%);
          left: 50%;
          width: 30%;
          p {
            &:nth-child(1) {
              color: $color-1;
              font-weight: bold;
              font-size: 30px;
              margin-bottom: 5px;
            }
            &:nth-child(2) {
              span {
                color: black;
                font-weight: bold;
                letter-spacing: 0px;
              }
            }
          }
          img{
            position: absolute;
            top: -60px;
            right: 140px;
            max-width: 110px;
          }
        }
        .step-3 {
          position: absolute;
          top: -70px;
          transform: translate(-50%, -50%);
          left: 90%;
          width: 30%;
          p {
            &:nth-child(1) {
              color: $color-1;
              font-weight: bold;
              font-size: 30px;
              margin-bottom: 5px;
            }
            &:nth-child(2) {
              span {
                color: black;
                font-weight: bold;
                letter-spacing: 0px;
              }
            }
          }
        }
      }
    }
    .entrepriseInfos {
      position: relative;
      .entreprisePics {
          img{
            width: 100%;
            height: 100%;
          }
      }
      .entrepriseNumber {
        position: relative;
        background-color: $color-1;
        height: 150px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        display: flex;
        z-index: 9;
        div {
          width: 25%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          color: $white;
          p {
            &:nth-child(1) {
              font-weight: bold;
              font-size: 32px;
              margin-bottom: 16px;
            }
            &:nth-child(2) {
              font-size: 20px;
            }
          }
        }
      }
    }
    .entrepriseCitation {
      text-align: center;
      height: 400px;
      img {
        height: 190px;
        object-fit: contain;
        margin: 0 auto;
      }
      #citation {
        text-align: center;
        position: relative;
        max-width: 530px;
        img {
          position: absolute;
          height: 80px;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        p {
          font-weight: bold;
          font-style: italic;
          margin-bottom: 10px;
          font-size: 20px;
        }
        span {
          font-weight: bold;
          font-style: italic;
          color: $black;
        }
      }
    }
  }
}
