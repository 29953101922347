.banner {
  width: 100%;
  height: 480px;
  background: rgb(238, 245, 241);
  background: linear-gradient(
    180deg,
    rgba(238, 245, 241, 1) 0%,
    rgba(238, 245, 241, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  // background-color: $color-3;
  color: $black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    margin-bottom: 35px;
  }
  &.bannerNoContent {
    min-height: 200px;
    height: 100%;
    padding: 24px 0;
    h1 {
      margin-bottom: 0;
    }
  }
  // p {
  //   // max-width: 840px;
  // }
}

.uneactualite {
  .bannerNoContent {
    min-height: 350px;
  }
}

.banner-inspiration {
  width: 100%;
  height: 320px;
  max-height: 100%;
  background-image: url("../assets/accueil-inspiration.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-position: center;
  &::before {
    clear: both;
  }
  &::before {
    content: "";
    background: rgb(238, 245, 241);
    background: linear-gradient(
      180deg,
      rgba(238, 245, 241, 1) 0%,
      rgba(238, 245, 241, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    // background-color: $white;
    position: absolute;
    width: 100%;
    height: 320px;
    opacity: 0.8;
  }
  .position-banner-inspiration {
    z-index: 9;
  }
  h1 {
    z-index: 1;
    color: $color-1;
  }
  .pretitre {
    font-size: 22px;
    margin-bottom: 30px;
  }
}

.srBanner {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-image: url("../assets/montant_gondole_equerre_sr_l_evolutif_1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  &::before {
    clear: both;
  }
  &::before {
    content: "";
    background-color: $color-1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0.33;
  }
  .position-banner-srevolutif {
    z-index: 9;
  }
  h2 {
    z-index: 1;
    color: $white;
    margin-bottom: 48px;
  }
  p {
    margin-top: 24px;
    color: $white;
    margin-bottom: 32px;
  }
  .srBannerBox {
    text-align: center;
    width: 50%;
    p {
      text-align: center;
      margin-bottom: 20px;
    }
  }
}

// Détails d'un réalisation
.realisationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgb(238, 245, 241);
  background: linear-gradient(
    180deg,
    rgba(238, 245, 241, 1) 0%,
    rgba(238, 245, 241, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  position: relative;
  // background-color: $color-2;
  // height: 590px;
  .realisationBannerContent {
    position: relative;
    padding-top: 64px;
    padding-bottom: 64px;
    // height: 590px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 60px;
    h1 {
      margin-top: 24px;
      margin-bottom: 32px;
    }
    .realisationAddToFav {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .containBannerAddToFav {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .realisationAddToFav {
        position: relative;
        top: 0;
      }
    }
  }
  .heroSwiper {
    // position: absolute;
    // width: 45vw;
    img,
    picture {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .realisationBannerImage {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .swiper {
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      width: 40%;
    }
    .swiper-pagination {
      left: auto;
      right: 30px;
      width: auto;
      background-color: rgba($white, 0.4);
      margin: 0;
      border-radius: 50px;
      display: flex;
      padding: 8px;

      .swiper-pagination-bullet {
        height: 12px;
        width: 12px;
        margin-bottom: 0;
        &.swiper-pagination-bullet-active {
          background: $color-1;
        }
      }
    }
    // .swiper-pagination {
    //   text-align: right;
    //   padding-right: 24px;
    //   width: 92.5%;
    //   .swiper-pagination-bullet {
    //     background-color: rgba(255, 255, 255, 0.4);
    //     width: 14px;
    //     height: 14px;
    //     &.swiper-pagination-bullet-active {
    //       background-color: $color-1;
    //     }
    //   }
    // }
  }
}

.bannerProduct {
  position: relative;
  z-index: -1;
  .containerImgFloating {
    position: absolute;
    img {
      object-fit: contain;
    }
  }
  .floating_1 {
    left: 0;
    top: 50%;
    transform: translate(0, -50%) rotate(-6deg);
    animation: float_1 6s ease-in-out infinite;
    img {
      width: 100%;
    }
  }
  .floating_2 {
    left: 30%;
    top: 0;
    transform: translate(-50%, -30%) rotate(-12deg);
    animation: float_2 6s ease-in-out infinite;
  }
  .floating_3 {
    left: auto;
    right: 10%;
    top: 0;
    transform: translate(-50%, -30%) rotate(112deg);
    animation: float_3 6s ease-in-out infinite;
  }
  .floating_4 {
    left: 30%;
    bottom: 0;
    transform: translate(-100%, 30%) rotate(12deg);
    animation: float_4 6s ease-in-out infinite;
  }
  .floating_5 {
    right: 10%;
    bottom: -30%;
    transform: translate(-50%, -30%) rotate(-32deg);
    animation: float_5 6s ease-in-out infinite;
  }
  .floating_6 {
    right: -5%;
    bottom: -5%;
    transform: translate(-50%, -50%) rotate(18deg);
    animation: float_6 6s ease-in-out infinite;
  }
}

@keyframes float_1 {
  0% {
    transform: translate(0%, -40%);
  }
  50% {
    transform: translate(-10%, -50%) rotate(-26deg);
  }
  100% {
    transform: translate(0%, -40%);
  }
}
@keyframes float_2 {
  0% {
    transform: translate(-40%, -30%);
  }
  50% {
    transform: translate(-50%, -20%) rotate(15deg);
  }
  100% {
    transform: translate(-40%, -30%);
  }
}
@keyframes float_3 {
  0% {
    transform: translate(-40%, -30%);
  }
  50% {
    transform: translate(-50%, -10%) rotate(40deg);
  }
  100% {
    transform: translate(-40%, -30%);
  }
}
@keyframes float_4 {
  0% {
    transform: translate(-100%, 30%);
  }
  50% {
    transform: translate(-80%, 20%) rotate(-20deg);
  }
  100% {
    transform: translate(-100%, 30%);
  }
}
@keyframes float_5 {
  0% {
    transform: translate(-50%, -30%);
  }
  50% {
    transform: translate(-40%, -20%) rotate(-20deg);
  }
  100% {
    transform: translate(-50%, -30%);
  }
}
@keyframes float_6 {
  0% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-60%, -40%) rotate(30deg);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

.BannerFavoris {
  width: 100%;
  height: 340px;
  background: rgb(238, 245, 241);
  background: linear-gradient(
    180deg,
    rgba(238, 245, 241, 1) 0%,
    rgba(238, 245, 241, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  color: $black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 28px;
    margin-bottom: 40px;
  }
}
