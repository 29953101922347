.candidatureContainer {
  .candidatureBox {
    .candidatureForm {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      .candidatureField {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        label {
          font-family: $HB !important;
          font-size: 16px;
          margin-bottom: 10px;
          display: flex;
          span {
            color: orangered;
          }
        }
        input,
        textarea {
          background-color: #f5f6f6;
          font-size: 16px;
          padding: 10px 15px;
          border-radius: 8px;
          resize: none;
          ::placeholder {
            font-style: italic;
          }
          &:focus,
          &:active {
            border: 1px solid $color-1;
          }
        }
        input[type="file"] {
          position: absolute;
          height: 100%;
          width: 100%;
          cursor: pointer;
        }
        .candidatureFieldFile {
          position: relative;
          height: 200px;
          .candidatureFieldFileCard {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: #f5f6f6;
            border-radius: 8px;
            pointer-events: none;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .candidatureFieldCheckBox {
          display: flex;
          width: 60%;
          label {
            margin: 0 0 0 10px;
          }
        }
        input[type="submit"] {
          text-transform: none;
          height: auto;
          margin-top: 0;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
          color: $white;
          font-family: $HR;
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 1.5px;
          background-color: $darkGrey;
          cursor: pointer;
          width: 260px;
          text-align: left;
        }
        &:nth-child(5) {
          grid-column-end: span 2;
        }
        &:nth-child(6) {
          grid-column-end: span 2;
        }
        &:nth-child(8) {
          grid-column-end: span 2;
          .candidatureFieldCheckBox {
            align-items: center;
            width: 100%;
            input{
              width: 20px;
          height: 20px;
          margin: 0px 10px 0 10px;
          cursor: pointer;
          accent-color: $color-1;
            }
            label {
              width: 100%;
            }
          }
        }
        &:nth-child(9) {
          grid-column-end: span 2;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  #contactFieldSubmit {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 270px;
    span {
      position: absolute;
      right: 10px;
      transform: rotate(-90deg);
      color: $white;
    }
  }
}
