.productsExplorerContainer,
.BannerFavoris {
  position: relative;
  .searchProductExplorer {
    position: relative;
    z-index: 10;
    margin-bottom: 30px;
    background-color: $white;
    user-select: none;
    padding: 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    // width: 95%;
    section {
      display: flex;
      justify-content: center;
      align-items: center;
      .containerBtnUniversCategories {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btnUniversFiltersSearch {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        color: $light-grey;
        cursor: pointer;
        img.imgUniversIcon {
          height: 24px;
        }
        img {
          height: 30px;
        }
        div {
          display: flex;
          margin-top: 10px;
          p {
            font-size: 14px;
            display: flex;
            span {
              font-size: 13px;
            }
          }
          svg {
            fill: $light-grey;
          }
          .turnArrow {
            transform: rotate(180deg) !important;
          }
        }
      }
    }
    .universFilterList {
      background-color: $white;
      width: 100%;
      z-index: 10;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-gap: 10px;
      grid-auto-rows: minmax(100px, auto);
      height: 0px;
      overflow: hidden;
      transition: height 0.3s;
      .universFilterListCard {
        height: 90px;
        margin: 8px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background-color: #f9f7f4;
          border-radius: 8px;
        }
        picture {
          width: 40px;
          height: 40px;
          border-radius: 100px;
          align-items: center;
          justify-content: center;
          display: flex;
          position: relative;
          z-index: 9;
        }
        img {
          height: 24px;
        }
        p {
          margin-top: 8px;
          font-size: 10px;
          text-align: center;
          text-transform: uppercase;
          font-weight: 600;
        }
      }
    }
    .universFilterListOpen {
      height: 100px;
    }
    .wrapperUniversFilter {
      display: flex;
      align-items: center;
    }
    .categoryFilterList {
      background-color: $white;
      width: 100%;
      height: 0;
      transition: height 0.3s;
      overflow: hidden;
      z-index: 10;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 10px;
      grid-auto-rows: minmax(100px, auto);
      .categoryFilterListCard {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:hover {
          p {
            color: $color-1;
          }
        }
        .categoryFilterListCardImg {
          background-color: #f9f7f4;
          max-height: 100%;
          height: 110px;
          cursor: pointer;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            padding: 10px;
            width: 130px;
            height: 110px;
            transition: 0.5s;
            object-fit: contain;
          }
        }
        p {
          margin-top: 18px;
          text-transform: uppercase;
          font-size: 11px;
          cursor: pointer;
          text-align: center;
          line-height: 15px;
          transition: 0.5s;
        }
        &:last-child {
          img {
            padding: 0;
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .categoryFilterListOpen {
      height: 160px;
    }
  }
  .productsVisualizerContainer {
    margin-top: 5px;
    h2 {
      margin-top: 20px;
    }
    p {
      margin: 20px 0 20px 0;
    }
    .productsVisualizerLabelsBox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .productsFilterLabel {
        background-color: $white;
        border: 1px solid rgba(144, 144, 144, 0.5);
        width: auto;
        margin-right: 10px;
        margin-bottom: 5px;
        padding: 6px 8px 6px 12px;
        display: flex;
        cursor: context-menu;
        border-radius: 8px;
        p {
          margin: 0;
          font-size: 14px;
          color: rgba(144, 144, 144, 0.6);
          font-family: $HR;
          margin-right: 7px;
        }
        span {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        svg {
          font-size: 16px;
          fill: rgba(144, 144, 144, 0.6);
        }
        &:hover {
          border-color: $color-1;
          p {
            color: $color-1;
          }
          svg {
            fill: $color-1;
          }
        }
      }
    }
    .productsFilter {
      .titleFilter {
        font-family: $HB;
        font-size: 22px;
        &::after {
          content: "";
          display: block;
          position: relative;
          margin-top: 16px;
          margin-bottom: 16px;
          border-bottom: 1px solid #f4f4f4;
        }
      }
      .accordionFilter {
        box-shadow: none;
        margin: 0;
        .MuiButtonBase-root {
          height: 34px;
        }
        .MuiAccordionDetails-root {
          padding: 0 16px;
        }
        .accordionFilterList,
        .accordionFilterSizePicker {
          list-style: none;
          .accordionFilterParent {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
              margin: 8px 0;
              font-size: 14px;
            }
          }
        }
        .accordionFilterChildren {
          box-shadow: none;
          p {
            text-transform: lowercase;
          }
          .accordionFilterParent {
            padding-right: 18px;
          }
          input[type="checkbox"] {
            transform: scale(1.2);
            cursor: pointer;
            accent-color: $color-1;
          }
        }
      }
    }
    .productsGrid {
      .titleproducts {
        font-family: $HR;
        text-transform: uppercase;
        font-size: 16px;
        color: $light-grey;
        &::after {
          content: "";
          display: block;
          position: relative;
          margin-top: 16px;
          margin-bottom: 16px;
          border-bottom: 1px solid #f4f4f4;
        }
      }
      position: relative;
      .noProduct {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        padding-top: 100px;
        position: absolute;
      }
      .productCard {
        padding: 20px;
        a {
          display: block;
          color: $black;
          position: relative;
          img,
          picture {
            width: 100%;
            height: 250px;
            object-fit: contain;
            margin-bottom: 10px;
          }
          .productCardAddFav {
            position: absolute;
            right: 0;
          }
        }
        .pictureProductCard {
          width: 100%;
          height: 250px;
          object-fit: contain;
          margin-bottom: 10px;
        }
        .noPictureProductCard {
          width: 100%;
          height: 250px;
          margin-bottom: 10px;
          background-color: $color-2;
        }
        .titleProductCard {
          color: $color-1;
          font-size: 14px;
          font-family: $HR;
          text-transform: uppercase;
          margin-bottom: 10px;
        }
        .contentProductCard {
          font-family: $HB !important;
          font-size: 16px !important;
          line-height: 26px;
          h3 {
            font-size: 16px;
            line-height: 26px;
            font-family: $HR !important;
          }
          p {
            font-size: 16px;
            font-family: $HR !important;
            margin: 0 !important;
            line-height: 26px;
          }
        }
        &:hover {
          box-shadow: 0 0 10px #00000014;
          border-radius: 16px;
        }
      }
    }
  }
}

.css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root {
  border-color: #f9f7f4 !important;
  // color: $light-grey !important;
  &:hover {
    background-color: #f9f7f4 !important;
  }
}
.css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #f9f7f4 !important;
  border-color: #f9f7f4 !important;
}
