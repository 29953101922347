@import url("https://use.typekit.net/ngq4mag.css");

@font-face {
  font-family: "Hellix-Bold";
  src: url(../assets/fonts/Hellix-Bold.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Hellix-Regular";
  src: url(../assets/fonts/Hellix-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Hellix-Italic";
  src: url(../assets/fonts/Hellix-RegularItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Hellix-SemiBold";
  src: url(../assets/fonts/Hellix-SemiBold.ttf);
  font-display: swap;
}

$HB: "Hellix-Bold";
$HR: "Hellix-Regular";
$HI: "Hellix-Italic";
$HSB: "Hellix-SemiBold";
$P22: "p22-mackinac-pro";

$color-1: #00833e;
$color-2: #eef5f1;
$color-3: #f2f9f5;
$color-4: #f9f7f4;
$white: #ffffff;
$black: #000000;
$darkGrey: #22252c;
$grey: #495058;
$light-grey: #989898;
$success: #00833e;
$error: #d91f26;

$bla: #c9ded2;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // list-style-type: none;
  outline: none;
  border: none;
  font-family: $HR;
  line-height: 24px;
  letter-spacing: 1.2px;
  &::selection {
    background: $color-1;
    color: $white;
  }
}
html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-size: 16px;
  // height: 100%;
  position: relative;
  margin: 0;
  min-height: 100%;
  font-family: $HR;
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    background-color: $color-2;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: $color-2;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    // border-radius: 10px;
    background: $color-1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #007537;
  }
}
body {
  background: $white;
  color: $black;
}
.noScroll {
  overflow: hidden;
}
.contain_info_header {
  width: 100%;
  background-color: $color-1;
  padding: 8px;
  p {
    color: white;
    font-size: 12px;
    text-align: center;
  }
}
#root {
  min-height: 100%;
  position: relative;
}
h1 {
  font-size: 48px;
  line-height: 58px;
  font-family: $P22 !important;
  margin-bottom: 10px;
}
h2 {
  font-size: 38px;
  line-height: 48px;
  font-family: $P22 !important;
  margin-bottom: 10px;
}
h3 {
  font-size: 28px;
  line-height: 38px;
  font-family: $P22 !important;
  margin-bottom: 10px;
}
h4 {
  font-size: 24px;
  line-height: 34px;
  font-family: $HB !important;
  margin-bottom: 10px;
}
.h5 {
  font-size: 22px;
  line-height: 32px;
  font-family: $HR !important;
  margin-bottom: 10px;
}
p {
  font-size: 16px;
}
hr {
  height: 1px;
  background-color: #d1d1d1;
  margin: 20px 0;
}
.minip {
  font-size: 14px;
  color: $light-grey;
}
p span {
  letter-spacing: 1.2px;
}
span {
  font-size: 16px;
  letter-spacing: 1.2px;
  color: $color-1;
}
a {
  text-decoration: none;
  color: $black;
}
ul {
  padding-left: 20px;
}
.textcenter {
  text-align: center;
}
.scrollTop {
  position: fixed;
  bottom: 70px;
  right: 30px;
  background-color: $color-2;
  height: 50px;
  width: 50px;
  z-index: 20;
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;
  box-shadow: 0px 5px 10px rgb(140 140 140 / 28%);
  cursor: pointer;
  img {
    width: 50%;
  }
}
.error {
  color: $error;
  font-family: $HB !important;
}
.success {
  color: $color-1;
  font-family: $HB !important;
}

.pretitre {
  text-transform: uppercase;
  color: $black;
  letter-spacing: 2px;
}
.color1 {
  color: $color-1;
}
.colorBlack {
  color: $black !important;
}
.bold {
  font-weight: 700;
  font-family: $HB;
}
.position-relative {
  position: relative;
}

.pb90 {
  padding-bottom: 90px;
}
.pt90 {
  padding-top: 90px;
}
.pt120 {
  padding-top: 120px;
}
.pb120 {
  padding-bottom: 120px;
}
.mt90 {
  margin-top: 90px;
}
.mb90 {
  margin-bottom: 90px;
}
.pt24 {
  padding-top: 24px;
}
.pb24 {
  padding-bottom: 24px;
}
.pt32 {
  padding-top: 32px;
}
.pb32 {
  padding-bottom: 32px;
}
.pt64 {
  padding-top: 64px;
}
.pb64 {
  padding-bottom: 64px;
}
.pb56 {
  padding-bottom: 56px;
}
.pt48 {
  padding-top: 48px;
}
.pb48 {
  padding-bottom: 48px;
}
.pb16 {
  padding-bottom: 16px;
}

p {
  strong {
    font-weight: 600;
    font-family: $HB;
  }
}

.positionRelative {
  position: relative;
}

.h100 {
  height: inherit;
  max-height: 100%;
}

// PAGINATION SWIPER
.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}

// ARROW SCROLLDOWN
.arrowScroll {
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    border-top: 1px solid $darkGrey;
    width: 47%;
    height: 2px;
    left: 0;
  }
  &::after {
    right: 0;
    position: absolute;
    content: "";
    border-top: 1px solid $darkGrey;
    width: 47%;
    height: 2px;
  }
  .arrow-down {
    width: 65px;
    height: 65px;
    border: 2px solid $darkGrey;
    background-color: $darkGrey;
    border-radius: 50%;
    position: absolute;
    bottom: -26px;
    transition: ease-in;
    animation: down 1.5s infinite;
    -webkit-animation: bounce 1.5s infinite;
  }
  .arrow-down::before {
    content: "";
    position: absolute;
    top: 55%;
    left: 55%;
    transform-origin: left;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 20px;
    height: 20px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
  }

  @keyframes bounce {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translateY(15px);
    }
    40% {
      transform: translate(0);
    }
  }

  @-webkit-keyframes bounce {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translateY(15px);
    }
    40% {
      transform: translate(0);
    }
  }

  .fade-in {
    opacity: 1;
    transition: 1s all ease-in;
  }

  .fade-out {
    opacity: 0;
    transition: 1s all ease-out;
  }
}
